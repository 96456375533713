import React, { useEffect, useState } from "react";
import Boxyellow from "../../../assets/images/box-yellow.png";
import Bluebox from "../../../assets//images/box-blue.png";
import Redbox from "../../../assets//images/red-box.png";
import EmailIcons from "../../../assets//images/email.png";
import PasswordIcons from "../../../assets//images/password.png";
import AdminIcons from "../../../assets//images/admin.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { registerUser } from "../../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import "../SignStyles.css";
import { toast } from "react-toastify";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, registerError, registerUserStatus, token } = useSelector(state => state.auth);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem('token', token)
      const selectedPlan = localStorage.getItem('plan') !== null
      if (selectedPlan) {
        navigate('/pricing')
        return
      }
      navigate("/dashboard");
    }

    if (registerUserStatus == "failed") {
      toast.error(registerError, {});
    }
  }, [isAuthenticated, navigate, registerError, registerUserStatus, token]);

  const onSubmit = data => {
    let requestObj = {
      name: data.name,
      email: data.email,
      password: data.password,
    };
    dispatch(registerUser(requestObj));
  };

  const password = watch("password", "");

  return (
    <section className="create-account-sec p-100 position-relative">
      <div className="banner-animation-icons">
        <span className="yellow-box">
          <img src={Boxyellow} alt="" />
        </span>
        <span className="box-blue">
          <img src={Bluebox} alt="" />
        </span>
        <span className="red-box">
          <img src={Redbox} alt="" />
        </span>
      </div>

      <div className="form-sec-details position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 text-center">
              <div className="heading-head mb-5">
                <span className="sub-heading">Welcome</span>
                <h2>Create your account</h2>
              </div>
            </div>

            <div className="form-box-main">
              <div className="form-sec">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4 form-with-icons position-relative">
                    <label htmlFor="" className="form-label">
                      Full Name <span className="error-defin">*</span>
                    </label>
                    <input
                      {...register("name", { required: true })}
                      type="text"
                      className="form-control"
                      id=""
                      aria-describedby=""
                      pattern="^[A-Za-z]+$"
                      placeholder="Enter Name"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
                      }}
                    />
                    <span className="icons-left">
                      <img src={AdminIcons} alt="" />
                    </span>
                    {errors.name && (
                      <p className="error-defin">This field is required</p>
                    )}
                  </div>
                  <div className="mb-4 form-with-icons position-relative">
                    <label htmlFor="" className="form-label">
                      Email address <span className="error-defin">*</span>
                    </label>
                    <input
                      {...register("email", { required: true })}
                      type="email"
                      className="form-control"
                      id=""
                      aria-describedby=""
                      placeholder="Enter email address"
                    />
                    <span className="icons-left">
                      <img src={EmailIcons} alt="" />
                    </span>
                  </div>
                  <div className="mb-2 form-with-icons  position-relative">
                    <label htmlFor="" className="form-label">
                      Enter password <span className="error-defin">*</span>
                    </label>
                    <input
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        validate: {
                          hasLetter: value =>
                            /[a-zA-Z]/.test(value) || "Password must contain at least one letter",
                          hasDigit: value =>
                            /\d/.test(value) || "Password must contain at least one digit",
                          hasSpecialChar: value =>
                            /[`!$%^&*~()\-_=\+[\]{}\\|;:'",<.>/?@#]/.test(value) ||
                            "Password must include at least one special character from ~()-_=+[{]}\\|;:'\",<.>/?",
                        },
                      })}
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id=""
                      placeholder="Enter password"
                    />
                    <span className="icons-left">
                      <img src={PasswordIcons} alt="" />
                    </span>
                    <span
                      className="hide-show-icons"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                    {errors.password && (
                      <p className="error-defin">
                        It must be a combination of minimum 8 letters, numbers,
                        and symbols.
                      </p>
                    )}
                  </div>
                  <div className="mb-2 form-with-icons  position-relative">
                    <label htmlFor="" className="form-label">
                      Confirm Password <span className="error-defin">*</span>
                    </label>
                    <input
                      {...register("confirmPassword", {
                        required: "Confirm Password is required",
                        validate: value =>
                          value === password || "The passwords do not match",
                      })}
                      type="password"
                      className="form-control"
                      id=""
                      placeholder="Enter password"
                    />
                    <span className="icons-left">
                      <img src={PasswordIcons} alt="" />
                    </span>

                    {errors.confirmPassword && (
                      <p className="error-defin">Passwords do not match</p>
                    )}
                  </div>
                  <div className="mb-2 form-check d-flex justify-content-between flex-wrap align-items-center">
                    <div className="form-check cust-check d-flex align-items-center">
                      <input
                        {...register("termsCheck", { required: true })}
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault">
                        I agree to the terms and conditions
                      </label>
                    </div>
                    {errors.termsCheck && (
                      <p className="error-defin">This Check is required</p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 blue-btn">
                    Create an Account
                  </button>
                  <div className="border-btm text-center mt-4 mb-4 position-relative">
                    <span>Or</span>
                  </div>
                  <div className="text-btm text-center">
                    Have an account?{" "}
                    <Link to={"/signin"} className="link-btn">
                      {" "}
                      Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
