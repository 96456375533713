import React from "react";
import "./BannerStyles.css";
import {Link} from "react-router-dom";
import {MdOutlineFileUpload} from "react-icons/md";
import {useSelector} from "react-redux";

const BannerSection = () => {
  const {isAuthenticated} = useSelector(state => state.auth);
  return (
    <>
      <section className="banner-sec position-relative p-100">
        <span className="yellow-box">
          <img src="images/box-yellow.png" alt="" />
        </span>
        <span className="box-blue">
          <img src="images/box-blue.png" alt="" />
        </span>
        <span className="red-box">
          <img src="images/red-box.png" alt="" />
        </span>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="img-box position-relative">
                <span className="img-shape"></span>
                <div className="banner-img-box position-relative">
                  <img src="images/baner.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-box">
                <div className="banner-heading">
                  <h1>Save and share your files - effortlessly</h1>
                </div>
                <p>
                  SaveFiles integrates cloud storage, file and folder sharing,
                  monetization features, and more into a single platform.
                </p>

                {!isAuthenticated && (
                  <Link
                    rel="stylesheet"
                    to="/"
                    className="btn-file cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#videomodal">
                    <span
                      className="btn btn-primary"
                      style={{fontSize: "1.5rem"}}>
                      <MdOutlineFileUpload />
                      Upload File
                    </span>
                  </Link>
                )}

                {/* <a href="#" className="btn btn-primary">
                  Upload File
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerSection;
