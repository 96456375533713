import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNotificationsService, getProfileDetail, updatePassword, updateProfile, contactUsService, getDashboardDetailService, getTransactionHistoryService } from "../../services/profileService";

export const getUserDetail = createAsyncThunk(
    'profile/getUserDetail', async (_, { rejectWithValue }) => {
        try {
            const response = await getProfileDetail()
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateUserPassword = createAsyncThunk(
    'profile/updateUserPassword', async (data, { rejectWithValue }) => {
        try {
            const response = await updatePassword(data)
            return response.data;
        } catch (error) {
            return error.response.data
            // rejectWithValue(error.response.data)
        }
    }
);

export const updateUserProfile = createAsyncThunk(
    'profile/updateProfile', async (data, { rejectWithValue }) => {
        try {
            const response = await updateProfile(data)
            return response.data;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.response.data)
        }
    }
)

export const getNotifications = createAsyncThunk(
    'profile/getNotifications', async (_, { rejectWithValue }) => {
        try {
            const response = await getNotificationsService();
            return response.data;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.response.data)
        }
    }
)

export const contactUs = createAsyncThunk(
    'profile/contactUs', async (data, { rejectWithValue }) => {
        try {
            const response = await contactUsService(data);
            return response.data;
        } catch (err) {
            rejectWithValue(err.response.data)
        }
    }
)

export const getDashboardDetail = createAsyncThunk(
    'profile/getDashboardDetail', async (_, { rejectWithValue }) => {
        try {
            const response = await getDashboardDetailService();
            return response.data;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.response.data)
        }
    }
)

export const getTransactionHistory = createAsyncThunk(
    'profile/getTransactionHistory', async (_, { rejectWithValue }) => {
        try {
            const response = await getTransactionHistoryService();
            return response.data;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.response.data)
        }
    }
)

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        name: '',
        email: '',
        status: 'idle',
        error: null,
        changePasswordState: 'idle',
        updateProfileState: 'idle',
        updateProfileMessage: '',
        updateProfileSuccess: false,
        totalFileSize: 0,
        totalFileSizeGB: 0,
        userSubscription: null,
        getNotificationsState: false,
        notificationData: null,
        contactUsStatus: 'idle',
        dashboardData: [],
        getDashboardDetailStatus: 'idle',
        getTransactionHistoryStatus: 'idle',
        transactionHistoryData: [],
        changePasswordMessage: ''
    },
    reducers: {
        resetContactUsState: (state) => {
            state.contactUsStatus = 'idle'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetail.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(getUserDetail.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.name = action.payload.data.name
                state.email = action.payload.data.email
                state.totalFileSize = action.payload.data.totalFileSize
                state.totalFileSizeGB = action.payload.data.totalFileSizeGB
                state.userSubscription = action.payload.data.UserSubscription
            })
            .addCase(getUserDetail.rejected, (state) => {
                state.status = 'failed'
            })
            .addCase(updateUserPassword.pending, (state) => {
                state.changePasswordState = 'loading'
            })
            .addCase(updateUserPassword.fulfilled, (state, action) => {
                if(action.payload.success){
                    state.changePasswordState = 'succeeded'
                }else{
                    state.changePasswordState = 'failed'
                    state.changePasswordMessage = action.payload.message
                }
            })
            .addCase(updateUserPassword.rejected, (state) => {
                state.changePasswordState = 'failed'
            })
            .addCase(updateUserProfile.pending, (state) => {
                state.updateProfileState = 'loading'
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                state.updateProfileState = 'succeeded'
                if (action.payload.success) {
                    state.name = action.payload.data.name
                    state.email = action.payload.data.email
                }
                state.updateProfileMessage = action.payload.message
                state.updateProfileSuccess = action.payload.success
            })
            .addCase(updateUserProfile.rejected, (state, action) => {
                state.updateProfileState = 'failed'
                state.error = action.payload;
            })
            .addCase(getNotifications.pending, (state) => {
                state.getNotificationsState = 'loading'
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.getNotificationsState = 'succeeded'
                    state.notificationData = action.payload.data
                } else {
                    state.getNotificationsState = 'failed'
                }
            })
            .addCase(getNotifications.rejected, (state, action) => {
                state.getNotificationsState = 'failed'
            })
            .addCase(contactUs.pending, (state) => {
                state.contactUsStatus = 'loading'
            })
            .addCase(contactUs.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.contactUsStatus = 'succeeded'
                } else {
                    state.contactUsStatus = 'failed'
                }
            })
            .addCase(contactUs.rejected, (state, action) => {
                state.contactUsStatus = 'failed'
            })
            .addCase(getDashboardDetail.pending, (state) => {
                state.getDashboardDetailStatus = 'loading'
            })
            .addCase(getDashboardDetail.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.getDashboardDetailStatus = 'succeeded'
                    state.dashboardData = action.payload.data;
                } else {
                    state.getDashboardDetailStatus = 'failed'
                }
            })
            .addCase(getDashboardDetail.rejected, (state, action) => {
                state.getDashboardDetailStatus = 'failed'
            })
            .addCase(getTransactionHistory.pending, (state) => {
                state.getTransactionHistoryStatus = 'loading'
            })
            .addCase(getTransactionHistory.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.getTransactionHistoryStatus = 'succeeded'
                    state.transactionHistoryData = action.payload.data.data;
                } else {
                    state.getTransactionHistoryStatus = 'failed'
                }
            })
            .addCase(getTransactionHistory.rejected, (state, action) => {
                state.getTransactionHistoryStatus = 'failed'
            })
    }
})

export const { resetContactUsState } = profileSlice.actions;
export default profileSlice.reducer;