import React from "react";
import NotFound from "../Pages/NotFound/NotFound";

export default class ErrorBoundary extends React.Component{
    state = {
        hasError: false
    };

    static getDerivedStateFromError(error){
        return {
            hasError: true
        };
    }

    componentDidCatch(error, info){
        console.log(error, info);
    }

    render(){
        if(this.state.hasError){
            return <div><NotFound/></div>
        }
        return this.props.children;
    }
}