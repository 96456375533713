// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::selection {
  background: var(--text_black);
  color: var(--text_white)
}

:root {
  --text_white: #ffffff;
  --text_black: #000000;
  --body-black: #191C23;
  --body-theme-black: #1d232c;
  --text_blue: #127EFF;
  --font_Ubuntu: "Ubuntu", sans-serif;
  --font_Poppins: "Poppins", sans-serif;
}

body {
  overflow-x: hidden;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  overflow-x: hidden;
  color: var(--text_white);
  background-color: var(--body-black);
  font-family: var(--font_Poppins);
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/index.css"],"names":[],"mappings":"AAQA;EACE,6BAA6B;EAC7B;AACF;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;EACrB,2BAA2B;EAC3B,oBAAoB;EACpB,mCAAmC;EACnC,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,wBAAwB;EACxB,mCAAmC;EACnC,gCAAgC;EAChC,SAAS;AACX","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n::-moz-selection {\n  background: var(--text_black);\n  color: var(--text_white)\n}\n\n::selection {\n  background: var(--text_black);\n  color: var(--text_white)\n}\n\n:root {\n  --text_white: #ffffff;\n  --text_black: #000000;\n  --body-black: #191C23;\n  --body-theme-black: #1d232c;\n  --text_blue: #127EFF;\n  --font_Ubuntu: \"Ubuntu\", sans-serif;\n  --font_Poppins: \"Poppins\", sans-serif;\n}\n\nbody {\n  overflow-x: hidden;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  overflow-x: hidden;\n  color: var(--text_white);\n  background-color: var(--body-black);\n  font-family: var(--font_Poppins);\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
