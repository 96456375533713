import React, { useEffect } from "react";
import $ from "jquery";
import "./headerStyle.css";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { MdOutlineFileUpload } from "react-icons/md";
import Logo from '../../assets/images/logo.svg'

const Header = ({ videoPlayer = false }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const handleScroll = () => {
      if ($(window).scrollTop() > 100) {
        $("header").addClass("header-appear");
      } else {
        $("header").removeClass("header-appear");
      }
    };

    // Attach the scroll event listener
    $(window).on("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      $(window).off("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header className="fixed-header header">
        <div className="container">
          <div className="inner-header d-flex flex-wrap align-items-center justify-content-between">
            <div className="logo-head">
              <Link to={"/"} className="logo-img">
                {/* <a href="index.html" className="logo-img"> */}
                <img src={Logo} alt="" />
                {/* </a> */}
              </Link>
            </div>
            <div
              className={isAuthenticated ? "offcanvas offcanvas-start":"offcanvas offcanvas-start isAuthenticated" }
              tabIndex="-1"
              id="menucanvas"
              aria-labelledby="menucanvas">
              <div className="offcanvas-header">
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close">
                  <span className="clos-f-btn"></span>
                  <span className="close-s-btn"></span>
                </button>
              </div>
              <div className="offcanvas-body">
                <div className="navigation d-flex flex-wrap align-items-center">
                  <nav>
                    <ul className="d-flex flex-wrap align-items-center list-unstyled">
                      {/* <li>
                        <Link to={"/support"}>Support</Link>
                      </li> */}
                      <li>
                        <Link to={"/pricing"}>Pricing</Link>
                      </li>
                      {!isAuthenticated ? (
                        <li>
                          <Link to={"/signin"}>Login</Link>
                        </li>
                      ) : (
                        <li onClick={sessionStorage.setItem('tabIndex', 1)}>
                          <Link to={"/dashboard"}>Account</Link>
                        </li>
                      )}
                      {!isAuthenticated && (
                        <li>
                          <Link to={"/signup"}>Create an Account</Link>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            {!isAuthenticated && (
              <div className="btn-box-header">
                <div className="btn-box-with-menu-btn">
                  <ul className="d-flex align-items-center">
                    <li>
                      <Link
                        rel="stylesheet"
                        to="/signin"
                        className="btn-file btn btn-primary d-flex justify-content-center align-content-center cursor-pointer"
                      >
                        <span className=" btn-header">
                          <MdOutlineFileUpload />
                          Upload File
                        </span>
                      </Link>
                      {/* <a href="#" className="btn-primary btn-header">
                      Upload File
                    </a> */}
                    </li>
                  </ul>

                  <a
                    className="btn btn-mobile-menu"
                    data-bs-toggle="offcanvas"
                    href="#menucanvas"
                    data-bs-target="#menucanvas"
                    role="button"
                    aria-controls="menucanvas">
                    <span className="f-btn"></span>
                    <span className="s-btn"></span>
                    <span className="t-btn"></span>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
