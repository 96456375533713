// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.savefile-section .savefile-box .icons svg {
    font-size: 50px;
    color: var(--text_blue);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Landing/SaveFilesSection/SaveFiles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".savefile-section .savefile-box .icons svg {\n    font-size: 50px;\n    color: var(--text_blue);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
