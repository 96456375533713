import React from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import '../Pages/Landing/styles.css'
import Upload from '../Components/Modal/Upload/Upload';

const UnauthenticatedLayout = ({ children }) => {
  return (
    <>
      <div>
        <Header></Header>
        <div className='main'>{children}</div>
        <Footer></Footer>
      </div>
      <Upload></Upload>
    </>
  );
};

export default UnauthenticatedLayout;
