import React from 'react'
import './styles.css';
import BannerSection from './BannerSection/Banner';
import SaveFiles from './SaveFilesSection/SaveFiles';
import Feature from './FeatureSection/Feature';
import Program from './ProgramSection/Program';
import GetStarted from './GetStartedSection/GetStarted';
import GetContact from './GetContactSection/GetContact';
import Upload from '../../Components/Modal/Upload/Upload';

const Landing = () => {
  return (
    <>
          <BannerSection></BannerSection>
          <SaveFiles></SaveFiles>
          <Feature></Feature>
          <Program></Program>
          {/* <GetStarted></GetStarted> */}
          <GetContact></GetContact>
        <Upload></Upload>
    </>
  )
}

export default Landing