// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-single-sec {
  .btn-color-box {
    position: relative;
    min-width: 212px;
    padding: 15px 10px;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    z-index: 1;
    border: 0;
    font-weight: bold;
    transition: all 500ms ease;
    box-shadow: 2px 4px 6px 0px #00000040 inset;
    box-shadow: -2px -4px 6px 0px #00000040 inset;
    background: var(--text_blue);
    color: var(--text_white);
  }

  .download-btn.btn.btn-primary {
    padding: 16px;
    border-radius: 9px;
    font-weight: 600;
  }

  .video-btn-btm {
    .subheading {
      position: relative;

      &:before {
        content: "";
        width: 150px;
        height: 2px;
        background: var(--text_white);
        position: absolute;
        top: -15px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }

  .field-copy-video-box {
    label {
      font-weight: 500;
      font-size: 18px;
    }

    textarea {
      background: transparent;
      border: 1px solid #6e6e6e !important;
      color: var(--text_white);
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/VideoPlayer/VideoPlayer.css"],"names":[],"mappings":"AAAA;EACE;IACE,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,iBAAiB;IACjB,0BAA0B;IAC1B,2CAA2C;IAC3C,6CAA6C;IAC7C,4BAA4B;IAC5B,wBAAwB;EAC1B;;EAEA;IACE,aAAa;IACb,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE;MACE,kBAAkB;;MAElB;QACE,WAAW;QACX,YAAY;QACZ,WAAW;QACX,6BAA6B;QAC7B,kBAAkB;QAClB,UAAU;QACV,OAAO;QACP,QAAQ;QACR,cAAc;MAChB;IACF;EACF;;EAEA;IACE;MACE,gBAAgB;MAChB,eAAe;IACjB;;IAEA;MACE,uBAAuB;MACvB,oCAAoC;MACpC,wBAAwB;MACxB,eAAe;MACf,gBAAgB;IAClB;EACF;AACF","sourcesContent":[".video-single-sec {\n  .btn-color-box {\n    position: relative;\n    min-width: 212px;\n    padding: 15px 10px;\n    overflow: hidden;\n    display: inline-block;\n    text-align: center;\n    border-radius: 4px;\n    z-index: 1;\n    border: 0;\n    font-weight: bold;\n    transition: all 500ms ease;\n    box-shadow: 2px 4px 6px 0px #00000040 inset;\n    box-shadow: -2px -4px 6px 0px #00000040 inset;\n    background: var(--text_blue);\n    color: var(--text_white);\n  }\n\n  .download-btn.btn.btn-primary {\n    padding: 16px;\n    border-radius: 9px;\n    font-weight: 600;\n  }\n\n  .video-btn-btm {\n    .subheading {\n      position: relative;\n\n      &:before {\n        content: \"\";\n        width: 150px;\n        height: 2px;\n        background: var(--text_white);\n        position: absolute;\n        top: -15px;\n        left: 0;\n        right: 0;\n        margin: 0 auto;\n      }\n    }\n  }\n\n  .field-copy-video-box {\n    label {\n      font-weight: 500;\n      font-size: 18px;\n    }\n\n    textarea {\n      background: transparent;\n      border: 1px solid #6e6e6e !important;\n      color: var(--text_white);\n      font-size: 16px;\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
