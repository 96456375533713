import React from "react";
import "./ProgramStyle.css";
import {Link} from "react-router-dom";

const Program = () => {
  return (
    <>
      <section className="programs-sec p-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12">
              <div className="text-box">
                <div className="heading-head mb-5">
                  <span className="sub-heading">Partner Program</span>
                  <h2>
                    Earn money by hosting and sharing your files with SaveFiles
                  </h2>
                </div>
                <p>
                  Earn a fixed amount per 10,000 downloads or streams, with
                  rates determined by the country of origin. Minimum payout is
                  $10. Partner with us today!
                </p>
                <Link to={"/partner-program"} className="btn btn-primary mt-4">
                  Start Earning Now
                </Link>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="img-box position-relative">
                <span className="img-shape"></span>
                <div className="programs-img-box position-relative">
                  <img src="images/planet-globe.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Program;
