import React from "react";
import Boxyellow from "../../assets/images/box-yellow.png";
import Bluebox from "../../assets/images/box-blue.png";
import Redbox from "../../assets/images/red-box.png";
import "../CopyRight/CopyRightStyles.css";
import ContactUsFooterTop from "../../Components/ContactUsFooterTop/ContactUsFooterTop";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="login-sec p-100 position-relative">
        <div className="banner-animation-icons">
          <span className="yellow-box">
            <img src={Boxyellow} alt="" />
          </span>
          <span className="box-blue">
            <img src={Bluebox} alt="" />
          </span>
          <span className="red-box">
            <img src={Redbox} alt="" />
          </span>
        </div>

        <div className="copyright-sec">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 text-center">
                <div className="heading-head mb-5">
                  <span className="sub-heading">Security Policy</span>
                  <h2>Privacy Policy</h2>
                </div>
              </div>
              <div className="col-md-12">
                <div className="copyright-details position-relative">
                  <p className="mb-3">
                    <strong>Information We May Collect</strong>{" "}
                  </p>
                  <p>
                    You may post content to the Services without creating an
                    account. When you do this, our servers automatically collect
                    certain technical information (“Technical Data”) about your
                    use of the Services. Technical Data is collected as follows:
                  </p>
                  <ul className="listing-savefile">
                    <li>
                      Log Data. Our servers automatically recognize and log
                      visitors' IP addresses (the number assigned to computers
                      on the Internet), Internet service provider, browser type,
                      computer platform, referring web site, date and time of
                      access, links clicked through, and possibly other
                      information as well.
                    </li>
                    <li>
                      Cookies. Depending on how you access the Services, we may
                      use the standard "cookies" feature of major browser
                      applications or similar technologies such as HTML 5 local
                      storage that allow us to store a small piece of data on
                      your computer about your visit to our Services. When we
                      use cookies, we may use “session” cookies (that last until
                      you close your browser) or “persistent” cookies (that last
                      until you or your browser delete them). For example, we
                      may use cookies to store your preferences or other
                      settings so you don‘t have to set them up every time you
                      visit our Services. Some of the cookies we use are
                      associated with your account (including personal
                      information about you, such as the email address you gave
                      us), and other cookies are not. Advertisers may also use
                      cookies on our Services. We have no access to or control
                      over these cookies once our third-party advertisers have
                      been given permission to set cookies. This Policy only
                      covers use of cookies by us, and does not cover use of
                      cookies by any third-party advertiser.
                    </li>
                    <li>
                      Other Device and Contact Information. Certain features of
                      the Services require access to your device's native
                      phonebook and image storage applications (e.g., to find
                      friends or to store a message in your photo gallery). If
                      you choose to find friends, the phone numbers in your
                      device's native phonebook will be transmitted to our
                      servers. When you create an account with us, you may
                      voluntarily provide us with certain information in
                      addition to the Technical Data above which may include
                      personally identifying information (“Account
                      Information”).
                    </li>
                    <li>
                      Account Information,This may include, without limitation,
                      your username, password, email address, profile photo or
                      avatar, biographic information, comments, and any other
                      information you may provide to us when you sign up or as
                      you use the Services. When you use a third-party service,
                      like Facebook or Twitter, to create and authenticate an
                      account, you also agree to their terms of service, privacy
                      policy, and other agreements between you and them.
                    </li>
                  </ul>
                  <p className="mb-3">
                    <strong>Use of Information</strong>
                  </p>
                  <p>
                    We use the information we collect to provide our Services to
                    you, as well as to improve them, develop new products, and
                    protect ourselves and our users. For example, we may keep
                    track of how people use two different versions of a product,
                    which can help us learn which version is better.
                  </p>
                  <h3>We may also use your information as follows:</h3>
                  <ul className="listing-savefile">
                    <li>
                      Account Information. We may use your personal information
                      to verify your identity or to help your friends find you
                      on the Services. We may also use your contact information
                      to respond to your questions or comments, to inform you of
                      any changes to our Services, to send you additional
                      information about SaveFiles and/or the Services, and to offer
                      you other products, programs or services that we believe
                      may be of interest to you from time to time.
                    </li>
                    <li>
                      Technical Data. We use anonymous Technical Data to analyze
                      our website traffic, but we do not examine this
                      information for individually identifying information. In
                      addition, we may use anonymous IP addresses to help
                      diagnose problems with our servers, to administer our
                      Services, or to display the content according to your
                      preferences. Traffic and Site activity information may
                      also be shared with advertisers and other third parties on
                      an aggregate and anonymous basis.
                    </li>
                    <li>
                      Cookies. We may use cookies (and other technologies having
                      similar functions, such as HTML 5 local storage) to
                      deliver content specific to your interests, to remember
                      you when you return to the Services, or for other
                      purposes. Promotions or advertisements displayed on our
                      Services may also contain cookies (or technologies with
                      similar function). We do not have access to or control
                      over information collected by outside advertisers on our
                      Services.
                    </li>
                  </ul>
                  <p className="mb-3">
                    <strong>Disclosure of Information</strong>
                  </p>
                  <p>
                    When you post content to the Services, your content becomes
                    public and anyone can view it. We may disclose other
                    information about you or your use of the Services, which may
                    include Technical Data and/or Account Information, under
                    certain appropriate circumstances, such as:
                  </p>
                  <ul className="listing-savefile">
                    <li>
                      When we have a good-faith belief that we must in order to
                      comply with the law or with legal process served on us or
                      our affiliates
                    </li>
                    <li>
                      When necessary to protect and defend our rights or
                      property, or those of our users, or to act under exigent
                      circumstances to protect the safety of our users or the
                      public at large; or
                    </li>
                    <li>
                      To facilitate a sale of the Services or a merger,
                      acquisition, bankruptcy, dissolution, reorganization, or
                      similar transaction or proceeding that involves the
                      transfer of the information described in this Policy.
                    </li>
                  </ul>
                  <p className="mb-3">
                    <strong>Your Choices</strong>
                  </p>
                  <p>
                    You have various choices about your information on the
                    Services:
                  </p>
                  <ul className="listing-savefile">
                    <li>
                      You can post content to the Services without signing up
                      for an account, in which case we only collect Technical
                      Data and Device Data from you.
                    </li>
                    <li>
                      When you do create an account with us, you can access and
                      edit your profile at any time accessing the Settings page
                      when logged in.
                    </li>
                    <li>
                      You can grant or revoke permission for SaveFiles and/or any of
                      our Services to access Device Information. If you decide
                      to revoke this permission, you can do so by following the
                      standard uninstall process and removing the application
                      from your device. Alternatively, on iOS devices, you can
                      grant or revoke your consent at any time and prevent us
                      from continuing to access your phonebook and image storage
                      applications by changing the settings on your device.
                    </li>
                    <li>
                      You can choose whether to accept cookies by changing the
                      settings on your browser. However, if you choose to
                      disable this function, your experience at our Services may
                      be diminished and some features may not work as they were
                      intended.
                    </li>
                    <li>
                      You may disable the collection of GPS information by
                      altering your device settings so that location information
                      is no longer made available, but if you do so this could
                      negatively affect your experience while using or otherwise
                      accessing the Services.
                    </li>
                    <li>
                      Although we hate to see you go, you may also close your
                      account at any time by going to your profile settings and
                      clicking the “Delete Account” link. When you close your
                      account all of your videos will be deleted and
                      unrecoverable. Comments associated with your account will
                      also be deleted.
                    </li>
                  </ul>
                  <p className="mb-3">
                    <strong>Information about Children</strong>
                  </p>
                  <p>
                    The Services are not intended for or directed towards
                    children under 13. We understand and are committed to
                    respecting the sensitive nature of children's privacy
                    online. If we learn or have reason to suspect that a user is
                    under 13, we will promptly take any action we may deem
                    appropriate, including without limitation conducting further
                    inquiry/investigation and/or deleting that user's account.
                    If you learn that your minor child has provided us with
                    personal information without your consent, please contact us
                    at admin@savefiles.com.
                  </p>
                  <h3>Notice to Users Outside the United Kingdom</h3>
                  <p className="mb-3">
                    <strong>Security</strong>
                  </p>
                  <p>
                    We have security measures in place to prevent the loss,
                    misuse, and alteration of the information that we obtain
                    from you, but we can make no assurances regarding our
                    ability to prevent any such loss, misuse or alteration.
                  </p>
                  <p className="mb-3">
                    <strong>Consent to Communications</strong>
                  </p>
                  <p>
                    You consent to receive communications from us electronically
                    and agree that we may communicate with you by email, by
                    posting notices on the Services, or via push notifications
                    on your mobile device. You agree that all agreements,
                    notices, disclosures and other communications that we
                    provide to you electronically satisfy any legal requirement
                    that such communications be in writing.
                    <span className="d-block">
                      We will never email you to ask for your password or other
                      account information. If you receive such an email, please
                      send it to us so we can catch the bad guys.
                    </span>
                  </p>
                  <p className="mb-3">
                    <strong>Contacting Us</strong>
                  </p>
                  <p>
                    If you have any questions about this Policy, or, our
                    practices related to our Services, please submit your
                    questions to admin@savefiles.com.
                  </p>
                  <p className="mb-3">
                    <strong>Changes</strong>
                  </p>
                  <p>
                    We may change this policy from time to time, and if we do
                    we’ll post any changes on this page. If you continue to use
                    the Services after those changes go into effect, you agree
                    to the revised Policy. If the changes are significant, we
                    may provide more prominent notice or get your consent as
                    required by law.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactUsFooterTop />
    </>
  );
};

export default PrivacyPolicy;
