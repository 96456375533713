// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-heading {
  h1 {
    margin: 0 0 16px;
    font-family: var(--font_Ubuntu);
    font-style: normal;
    font-weight: 700;
    color: var(--text_white);
  }
}

.banner-sec {
  .btn.btn-primary {
    @media screen and (max-width: 767px) {
      font-size: 18px !important;
      padding: 10px 10px;
      min-width: 182px;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Landing/BannerSection/BannerStyles.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;IAChB,+BAA+B;IAC/B,kBAAkB;IAClB,gBAAgB;IAChB,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE;MACE,0BAA0B;MAC1B,kBAAkB;MAClB,gBAAgB;IAClB;EACF;AACF","sourcesContent":[".banner-heading {\n  h1 {\n    margin: 0 0 16px;\n    font-family: var(--font_Ubuntu);\n    font-style: normal;\n    font-weight: 700;\n    color: var(--text_white);\n  }\n}\n\n.banner-sec {\n  .btn.btn-primary {\n    @media screen and (max-width: 767px) {\n      font-size: 18px !important;\n      padding: 10px 10px;\n      min-width: 182px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
