import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { remoteUpload } from '../../services/remoteUploadService';

export const remoteUrlUpload = createAsyncThunk(
    'file/remoteUpload',
    async (data, { rejectWithValue }) => {
        try {
            const response = await remoteUpload(data);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

const remoteUploadSlice = createSlice({
    name: 'remoteUpload',
    initialState: {
        isRemoteUploadStatus: 'idle',
        data: null
    },
    reducers: {
        resetValue: (state, action) => {
            state.isRemoteUploadStatus = 'idle';
            state.data = null
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(remoteUrlUpload.pending, (state) => {
            state.isRemoteUploadStatus = 'loading'
        })
        .addCase(remoteUrlUpload.fulfilled, (state, action) => {
            state.isRemoteUploadStatus = 'succeeded'
            // state.data= action.payload.data;
        })
        .addCase(remoteUrlUpload.rejected, (state) => {
            state.isRemoteUploadStatus = 'failed'
        })
    }
})

export const { resetValue } = remoteUploadSlice.actions;
export default remoteUploadSlice.reducer;