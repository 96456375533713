import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getFileUrlV2, getOtherFileDetails } from "../../features/files/fileSlice"; // Redux action
import { useDispatch, useSelector } from "react-redux";
import { getFileDetails } from "../../services/fileService";
import { getVideoSettings } from "../../features/videoSetting/videosettingSlice";
import NotFound from "../NotFound/NotFound";
import { toast } from "react-toastify";

const loadPlyrAssets = () => {
    const plyrCSS = document.createElement('link');
    plyrCSS.rel = 'stylesheet';
    plyrCSS.href = 'https://cdn.plyr.io/3.6.12/plyr.css';
    document.head.appendChild(plyrCSS);

    const plyrJS = document.createElement('script');
    plyrJS.src = 'https://cdn.plyr.io/3.6.12/plyr.polyfilled.js';
    plyrJS.async = true;

    return new Promise((resolve) => {
        plyrJS.onload = () => {
            resolve();
        };
        document.body.appendChild(plyrJS);
    });
};

const AudioPlayer = () => {
    const { videoSetting } = useSelector((state) => state.videosetting)
    const [audioUrl, setAudioUrl] = useState("");
    const { id } = useParams();
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.file);
    const [audioName, setAudioName] = useState();
    const [fileSize, setFileSize] = useState();
    const [fileErrorFound, setFileErrorFound] = useState(false);
    const audioRef = useRef(null);
    const playerRef = useRef(null); // For storing the Plyr instance

    useEffect(() => {
        const fetchFileDetails = async () => {
            try {
                // Dispatch to fetch file details initially
                const fileDetails = await getFileDetails(id);
                const data = {
                    id: id,
                    fileName: fileDetails?.data?.data?.name
                }
                dispatch(getFileUrlV2(data)).then((res) => {
                    const Audio = res?.payload?.data?.url;
                    setAudioUrl(Audio);
                })

            } catch (error) {
                setFileErrorFound(true);
                toast.error(error?.response?.data?.message)
                console.error("Error fetching file details:", error);
            }
        };

        fetchFileDetails();
    }, [id]);

    useEffect(() => {
        dispatch(getVideoSettings());
        dispatch(getOtherFileDetails(id)).then((res) => {
            setAudioName(res?.payload?.data?.fileName);
            setFileSize(res?.payload?.data?.fileSize);
        })
    }, [dispatch]);

    // Load Plyr assets only once when the component mounts
    useEffect(() => {
        loadPlyrAssets().then(() => {
            playerRef.current = new window.Plyr(audioRef.current, {
                controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
                autoplay: false, // Disable autoplay
            });
        });

        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, []);

    // Reset and load new source when audioUrl changes
    useEffect(() => {
        if (playerRef.current && audioUrl) {
            // Stop the previous audio
            playerRef.current.stop();

            // Reset the audio element
            audioRef.current.src = ""; // Clear the source first
            audioRef.current.load();   // Reset the element

            // Set the new source
            audioRef.current.src = audioUrl;
            playerRef.current.source = {
                type: 'audio',
                sources: [
                    {
                        src: audioUrl,
                        type: 'audio/mp3',
                    },
                ],
            };
        }
    }, [audioUrl, getFileUrlV2]);

    if (fileErrorFound) {
        return (
            <NotFound />
        )
    }
    return (
        <section className="audio-single-sec p-100">
            <div className="container">
                <div className="row">
                    {loading && <p>Loading audio file...</p>}
                    {error && <p>Error loading audio: {error}</p>}
                    {!loading && !error && (
                        <>
                            <div className="col-md-12 text-center">
                                <h2>{audioName}</h2>
                            </div>
                            <div className="col-md-12">
                                <div className="audio-player-container">
                                    <audio ref={audioRef} className="plyr">
                                        {/* The source tag can be removed if Plyr manages it */}
                                    </audio>
                                </div>
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                {videoSetting?.videoAccessSettings?.showDownloadButton === true && (
                                    <a href={audioUrl} download className="download-btn btn btn-primary blue-btn">
                                        Download
                                    </a>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AudioPlayer;
