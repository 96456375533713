import React, {useState, useEffect, useRef} from "react";
import "./EmbeddedVideoPlayer.css";
import {Link, useParams} from "react-router-dom";
import {getFileUrl, videoViewCount} from "../../features/files/fileSlice";
import {useDispatch, useSelector} from "react-redux";
import { getIpAddressService } from "../../features/ipInfo/ipInfo";

const EmbeddedVideoPlayer = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [videoName, setVideName] = useState();
  const [fileSize, setFileSize] = useState();
  const {gettedfileData, getFileUrlStatus} = useSelector(state => state.file);
  const { ipInfoData } = useSelector(state => state.ipInfo)

  const {id} = useParams();
  const dispatch = useDispatch();

  const videoRef = useRef(null);
  const [isFivePercentWatched, setIsFivePercentWatched] = useState(false);

  useEffect(() => {
    const data = {
      fileId: id,
    };
    dispatch(getFileUrl(data));
  }, [id, dispatch]);

  useEffect(() => {
    if (gettedfileData.url != "") {
      setVideoUrl(gettedfileData.url);
      setVideName(gettedfileData.fileName);
      setFileSize(gettedfileData.fileSize);
    }
  }, [gettedfileData]);

  useEffect(() => {
    dispatch(getIpAddressService());
  }, [dispatch]);

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    const currentTime = video.currentTime;
    const duration = video.duration;

    if (duration > 0) {
      const percentageWatched = (currentTime / duration) * 100;
      if (percentageWatched >= 5 && !isFivePercentWatched) {
        setIsFivePercentWatched(true);
        const data = {
          id: id,
          ip: ipInfoData.ip,
          country: ipInfoData.country,
          code: ipInfoData.country
        }
        dispatch(videoViewCount(data))
      }
    }
  };

  return (
    <>
      <div className="full-resolution">
        <video
          ref={videoRef}
          onTimeUpdate={handleTimeUpdate}
          width="100%"
          height="100%"
          src={videoUrl}
          controls></video>
      </div>
    </>
  );
};

export default EmbeddedVideoPlayer;
