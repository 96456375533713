import React from 'react';
import DashboardHeader from '../Components/Dashboard/Header/Header';
import SideBar from '../Components/Dashboard/SideBar/SideBar'

const AuthenticatedLayout = ({ children }) => {
  return (
    <div>
      <div className='dashboard-body'>
      <DashboardHeader></DashboardHeader>
      <div className="dashboard-main-content d-flex flex-wrap">
          <SideBar></SideBar>
          <>{children}</>
      </div>
      </div>
    </div>
  );
};

export default AuthenticatedLayout;
