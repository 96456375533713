import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthenticatedLayout from '../Layouts/AuthenticatedLayout';
import { useSelector } from 'react-redux';

const AuthRoute = ({children}) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const navigate = useNavigate();

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/signin')
        }
    })

  return (
    <>
      <AuthenticatedLayout>
        {children}
      </AuthenticatedLayout>
    </>
  )
}

export default AuthRoute