import React, { useEffect, useState } from 'react'
import Boxyellow from '../../assets/images/box-yellow.png'
import Bluebox from '../../assets/images/box-blue.png'
import Redbox from '../../assets/images/red-box.png'
import { useDispatch, useSelector } from 'react-redux'
import { contactUs, resetContactUsState } from '../../features/profile/profileSlice'
import { toast } from 'react-toastify'

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('General Support');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const { contactUsStatus } = useSelector(state => state.profile)

    const handleSubmit = (e) => {
        e.preventDefault();
        if(name == '' || email == '' || subject == '' || message == ''){
            setError(true);
            return;
        }
        setError(false);
        const data = {
            "name": name,
            "email": email,
            "subject": subject,
            "message": message
        }
        dispatch(contactUs(data))
    }

    useEffect(() => {
        if(contactUsStatus == "succeeded"){
            toast.success("Message Sent Successfully!", {})
            setName('')
            setEmail('')
            setSubject('0')
            setMessage('')
            dispatch(resetContactUsState());
            return;
        }
        if(contactUsStatus == "failed"){
            toast.error("Something went wrong!", {})
            dispatch(resetContactUsState());
            return;
        }
    }, [contactUsStatus])

  return (
    <section className='login-sec p-100 position-relative'>
        <div className='banner-animation-icons'>
            <span className="yellow-box">
                <img src={Boxyellow} alt="" />
            </span>
            <span className="box-blue">
                <img src={Bluebox} alt="" />
            </span>
            <span className="red-box">
                <img src={Redbox} alt="" />
            </span>
        </div>

      
        <div className='form-sec-details position-relative'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10 col-md-12 text-center'>
                        <div className="heading-head mb-5">
                            <span className="sub-heading">Get in Touch</span>
                            <h2>
                            Contact Us
                            </h2>
                        </div>
                    </div>

                    <div className='form-box-main'>
                        <div className='form-sec'>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="mb-4 position-relative">
                                    <label htmlFor="" className='form-label'>Full Name <span className='error-defin'>*</span></label>
                                    <input type="text" required className="form-control" id="" value={name} aria-describedby="" placeholder='Enter Name' onChange={(e) => setName(e.target.value)}/>
                                </div>
                                <div className="mb-4 position-relative">
                                    <label htmlFor="" className='form-label'>Email address  <span className='error-defin'>*</span></label>
                                    <input type="email" required className="form-control" id="" value={email} aria-describedby="" placeholder='Enter email address' onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                <div className="mb-4 position-relative">
                                    <label htmlFor="" className='form-label'>Subject  <span className='error-defin'>*</span></label>
                                    <select required className="form-control form-select" aria-label="Default select example" value={subject} onChange={(e) => setSubject(e.target.value)}>
                                        <option value="General Support">General Support</option>
                                        <option value="DMCA">DMCA</option>
                                        <option value="Business Inquiries">Business Inquiries</option>
                                        <option value="Security-Related Support">Security-Related Support</option>
                                    </select>
                                </div>
                                <div className="mb-4 position-relative">
                                    <label htmlFor="" className="form-label">Message <span className='error-defin'>*</span></label>
                                    <textarea required className="form-control" id="" rows="3" placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                </div>
                                {
                                    error && <><div className='error-defin'>All(*) fields are mandatory</div><br></br></>
                                }
                                <button type="submit" className="btn btn-primary w-100 blue-btn">Send</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </section>
  )
}

export default ContactUs