import React from "react";
import './GetStartedStyles.css';

const GetStarted = () => {
  return (
    <>
      <section className="get-started-sec p-100 position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12">
              <div className="heading-head text-center">
                <span className="sub-heading">Get Started</span>
                <h2>3 easy steps to follow to start working and sharing </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center get-started-fixed-width">
            <div className="col-lg-6 col-md-6">
              <div className="img-box position-relative">
                <div className="get-tarted-img-box position-relative">
                  <img src="images/get-started-main.png" alt="" />
                </div>
                <div className="img-text-btm">
                  <div className="d-flex align-items-center">
                    <div className="icons">
                      <img src="images/notification.svg" alt="" />
                    </div>
                    <div className="text-box-position">
                      <span>New update!</span>
                      <p>Latest video is uploaded Watch Now</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="text-box">
                <div className="d-flex mb-5">
                  <div className="col-auto">
                    <div className="count-box d-flex align-items-center justify-content-center">
                      1
                    </div>
                  </div>
                  <div className="flex-grow-1 ps-3">
                    <h5 className="card-count-title">Sign Up</h5>
                    <p className="m-0">
                      Create your free account in just a few clicks.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-5">
                  <div className="col-auto">
                    <div className="count-box d-flex align-items-center justify-content-center">
                      2
                    </div>
                  </div>
                  <div className="flex-grow-1 ps-3">
                    <h5 className="card-count-title">Upload</h5>
                    <p className="m-0">
                      Start uploading your videos and share your story.
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-5">
                  <div className="col-auto">
                    <div className="count-box d-flex align-items-center justify-content-center">
                      3
                    </div>
                  </div>
                  <div className="flex-grow-1 ps-3">
                    <h5 className="card-count-title">Watch and share</h5>
                    <p className="m-0">
                      Explore our vast library of videos and find your next
                      favorite.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetStarted;
