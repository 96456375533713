import React, { useEffect, useState, useRef } from "react";
import "./VideoPlayer.css";
import { useParams } from "react-router-dom";
import { getFileUrl, videoViewCount } from "../../features/files/fileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getIpAddressService } from "../../features/ipInfo/ipInfo";

const VideoPlayer = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { gettedfileData, getFileUrlStatus } = useSelector(
    (state) => state.file
  );
  const { ipInfoData } = useSelector((state) => state.ipInfo);

  const [videoName, setVideName] = useState();
  const [fileSize, setFileSize] = useState();
  const videoRef = useRef(null);
  const [isFivePercentWatched, setIsFivePercentWatched] = useState(false);

  useEffect(() => {
    dispatch(getFileUrl(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (gettedfileData.url != "") {
      setVideoUrl(gettedfileData.url);
      setVideName(gettedfileData.fileName);
      setFileSize(gettedfileData.fileSize);
    }
  }, [gettedfileData]);

  useEffect(() => {
    dispatch(getIpAddressService());
  }, [dispatch]);

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    const currentTime = video.currentTime;
    const duration = video.duration;

    if (duration > 0) {
      const percentageWatched = (currentTime / duration) * 100;
      if (percentageWatched >= 5 && !isFivePercentWatched) {
        setIsFivePercentWatched(true);
        const data = {
          id: id,
          ip: ipInfoData.ip,
          country: ipInfoData.country,
          code: ipInfoData.country,
        };
        dispatch(videoViewCount(data));
      }
    }
  };

  return (
    <>
      <section className="video-single-sec  p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-head mb-5 text-center">
                <span className="sub-heading mb-1">Welcome videos</span>
                <h2>{videoName}</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="top-video">
                <video
                  ref={videoRef}
                  onTimeUpdate={handleTimeUpdate}
                  width="100%"
                  height="700"
                  src={videoUrl}
                  controls
                ></video>
              </div>
              <div className="mid-video mt-4 mb-4"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="video-btn-btm text-center">
                <a
                  href={videoUrl}
                  className="w-100 download-btn btn btn-primary mb-5"
                  download
                >
                  Download Video
                </a>
                <p className="subheading">Size: {fileSize} MB</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="field-copy-video-box">
                <label className="mb-2 w-100">Embed this video:</label>
                <textarea
                  className="w-100"
                  name="postContent"
                  defaultValue="I really enjoyed biking yesterday!"
                  rows={4}
                  cols={40}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoPlayer;
