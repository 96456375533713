import React from "react";
import Boxyellow from "../../assets/images/box-yellow.png";
import Bluebox from "../../assets/images/box-blue.png";
import Redbox from "../../assets/images/red-box.png";
import "../CopyRight/CopyRightStyles.css";
import "../PrivacyPolicy/PrivacyPolicyStyles.css";

const TermsAndCondition = () => {
  return (
    <>
      <section className="login-sec p-100 position-relative">
        <div className="banner-animation-icons">
          <span className="yellow-box">
            <img src={Boxyellow} alt="" />
          </span>
          <span className="box-blue">
            <img src={Bluebox} alt="" />
          </span>
          <span className="red-box">
            <img src={Redbox} alt="" />
          </span>
        </div>

        <div className="copyright-sec">
          <div className="container">
            <div className="row justify-content-center"></div>
            <div className="col-lg-12 col-md-12 text-center">
              <div className="heading-head mb-5">
                <span className="sub-heading">Know About</span>
                <h2>Terms and Conditions</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <p>
                {" "}
                Please review these terms and conditions of use carefully before
                using SaveFiles and the service available thereon. This document
                states the terms and conditions (“Terms”) upon which
                “SaveFiles,” (“we” or “us”), will provide service to you through
                SaveFiles and its related services (the “Website”). These Terms
                constitute a contractual agreement between you and us. By
                visiting, accessing, using, and/or joining (collectively
                “using”) the Website, you express your understanding and
                acceptance of these Terms. As used in this document, the terms
                “you” or “your” refers to you, any entity you represent, your or
                its representatives, successors, assigns and affiliates, and any
                of your or their devices. If you do not agree to be bound by
                these Terms, navigate away from the Website and cease using it.
              </p>
            </div>
            <div className="col-md-12">
              <div className="copyright-details position-relative">
                <p className="mb-3 mt-4">
                  <strong>Eligibility</strong>
                </p>
                <p>
                  You must be at least eighteen (18) years of age to use the
                  Website, unless the age of majority in your jurisdiction is
                  greater than eighteen (18) years of age, in which case you
                  must be at least the age of majority in your jurisdiction. You
                  represent and warrant that you are at least the minimum age as
                  required by the previous sentence. Use of the Website is
                  further not permitted where prohibited and you represent and
                  warrant that you are not otherwise prohibited to use the
                  Website.
                </p>
                <p className="mb-3">
                  <strong>Grant of Use</strong>
                </p>
                <p>
                  {" "}
                  We grant you a non-exclusive, non-transferable and limited
                  right to access, non-publicly display, and otherwise use the
                  Website, including all content available therein (the
                  “Content”) on your computer consistent with these Terms and
                  our technological restrictions. This grant is terminable by us
                  at will for any reason and at our sole discretion, with or
                  without prior notice. Upon termination, we may, but shall not
                  be obligated to: <br />
                  (i) delete or deactivate your account and <br />
                  (ii) block your e-mail and/or IP addresses or otherwise
                  terminate your use of the Website, and/or <br />
                  (iii) remove and/or delete any of your User Submissions
                  (defined below). You agree not to use or attempt to use the
                  Website after said termination. Upon termination, the grant of
                  your right to use the Website shall terminate, but all other
                  portions of these Terms shall survive. You acknowledge that we
                  are not responsible to you or any third party for the
                  termination of your grant of use. You must create an account
                  with us (an “Account”) to use many parts of the Website. You
                  represent and warrant that all information that you provide to
                  us in creating your Account is complete and accurate. You
                  shall update such information when it changes or when we
                  request. By creating an Account on behalf of a company or
                  other entity, you represent and warrant that you have the
                  authority and capacity to enter into these Terms and bind the
                  entity on its behalf. You shall not use another person or
                  entity’s Account without authorization. You shall be solely
                  responsible for maintaining the confidentiality of and
                  restricted access to your Account. You shall be solely
                  responsible for all activities that occur under your Account.
                  You shall notify us immediately of any breach of security or
                  unauthorized use of your Account. Without limiting the
                  indemnification and limitation of liability provisions hereof
                  (or any other provisions hereof), we shall not be liable for
                  any losses resulting from any unauthorized use of your Account
                  and you shall indemnify us and hold us harmless for any such
                  unauthorized use.
                </p>
                <p className="mb-3">
                  <strong>Payment</strong>
                </p>
                <p>
                  From time to time, Company may elect to offer its <br />
                  (1) “Affiliate Program”, which involves the Company making
                  payments to users of its Services that enroll in the program
                  and based on the number of Views of that user’s video posted
                  to the Services and <br />
                  (2) “Publisher Program”, which involves the Company making
                  payments to users that enroll in the program and based on the
                  number of Views of SaveFiles videos embedded by that user into
                  its own website. Specific payment amounts will be outlined on
                  the corresponding program description made available by
                  Company on the Website but all payment terms shall be subject
                  to the following conditions: As a participant in the
                  “Affiliate Program”, you agree that Company shall determine,
                  in its sole and absolute discretion, the number of Views for
                  which you shall be compensated. For purposes of these Terms, a
                  “View” applies to a video made available via the Services in
                  which the video complies with all laws and regulations, as
                  well as these Terms, and is viewed by a legitimate end user
                  intending to view that video and not for purposes of
                  generating multiple views. You agree not to engage in any
                  activity, directly or indirectly, to generate illegitimate
                  views such as using incentives or using any sort of automated
                  or other means. If there is a discrepancy between the number
                  of Views the Company believes were created and the number that
                  you believe were created, the Company will work with you to
                  determine the cause of such discrepancy but the Company’s
                  decision as to the number of Views for which it will
                  compensate you shall be final and Company may withhold some or
                  all payment to you for any reason it deems reasonable. Company
                  shall pay you all amounts due within ninety (90) calendar days
                  after the end of the calendar month in which the corresponding
                  payment was earned. You shall be responsible for any and all
                  federal, state, local and other taxes associated with or
                  arising from payments made to you hereunder. use.
                </p>
                <p className="mb-3">
                  <strong>Intellectual Property</strong>
                </p>
                <p>
                  The Content, excluding User Submissions and Third Party
                  Content (each as defined below), but including other text,
                  graphical images, photographs, music, video, software, scripts
                  and trademarks, service marks and logos contained therein
                  (collectively “Proprietary Materials”), are owned by and/or
                  licensed to us. All Proprietary Materials are subject to
                  copyright, trademark and/or other rights under the laws of
                  applicable jurisdictions, including domestic laws, foreign
                  laws, and international conventions. We reserve all our rights
                  over our Proprietary Materials. Except as otherwise explicitly
                  permitted, you agree not to copy, modify, publish, transmit,
                  distribute, participate in the transfer or sale of, create
                  derivative works of, or in any other way exploit, in whole or
                  in part, any Content.
                </p>
                <p className="mb-3">
                  <strong>User Submissions</strong>
                </p>
                <p>
                  You are entirely responsible for any and all materials you
                  upload, submit or otherwise make available via the Website,
                  including videos or any other communications or profile
                  information (collectively, “User Submissions”). User
                  Submissions cannot always be withdrawn. You acknowledge that
                  any disclosure of personal information in User Submissions may
                  make you personally identifiable and that we do not guarantee
                  any confidentiality with respect to User Submissions. You
                  shall be solely responsible for any and all of your own User
                  Submissions and any and all consequences of positing,
                  uploading, publishing or otherwise making them available. For
                  any of your User Submissions, you affirm, represent and/or
                  warrant that: <br />
                  (a) You own or have the necessary licenses, permissions,
                  rights or consents to use and authorize us to use all
                  trademarks, copyrights, trade secrets or other proprietary
                  rights in and to User Submissions for any and all uses
                  contemplated by the Website and these Terms; <br />
                  (b) You will not post, or allow anyone else to post, any
                  material that depicts any individual under the age of eighteen
                  (18) years engaging in any sexual acts and that you have
                  inspected and are maintaining written documentation, pursuant
                  to United States law18 U.S.C. § 2257 (whether you are subject
                  to United States law or not) and other analogous and
                  applicable laws, to confirm that all individuals in your User
                  Submission engaging in sexual acts are, in fact, over the age
                  of eighteen (18) years; and <br />
                  (c) You have written consent, release, and/or permission from
                  each and every identifiable individual in the User Submission
                  to use the name and/or likeness of each and every such
                  identifiable individual to enable use of the User Submission
                  for any and all uses contemplated by the Website and these
                  Terms. You further agree that you shall not submit material
                  that: <br />
                  (d) Is copyrighted, protected by trade secret or trademark
                  laws, or otherwise subject to third party proprietary rights,
                  including privacy and publicity rights, unless you are the
                  owner of such rights or have permission from the rightful
                  owner to submit the material and to grant us all of the
                  license rights granted herein; <br />
                  (e) Is obscene, vulgar, illegal, unlawful, defamatory,
                  fraudulent, libelous, harmful, harassing, abusive,
                  threatening, invasive of privacy or publicity rights, hateful,
                  racially or ethnically offensive, inflammatory, or otherwise
                  inappropriate as decided by us in our sole discretion; <br />
                  (f) Depicts illegal activities, promotes or depicts physical
                  harm or injury against any group or individual, or promotes or
                  depicts any act of cruelty to animals; <br />
                  (g) Impersonates any person or entity or otherwise
                  misrepresents you in any way, including creating a false
                  identity; <br />
                  (h) Would constitute, encourage or provide instructions for a
                  criminal offense, a violation of the rights of any party, or
                  that would otherwise create liability or violate any local,
                  state, national or international law; or <br />
                  (i) Is unsolicited or unauthorized advertising, promotion,
                  “spam” or any other form of solicitation; The above list is
                  only a representative list of inappropriate content not
                  allowed on the Website and we reserve the right to ban, remove
                  and prohibit any other content we, in our sole discretion,
                  determine to be inappropriate. You acknowledge that the
                  Website provides features that allow you to share User
                  Submissions with others or to make them public. It is your
                  sole responsibility to carefully consider what User
                  Submissions you choose to share or make public. We take no
                  responsibility whatsoever over your sharing or distribution of
                  User Submissions. We claim no ownership or control over User
                  Submissions or Third Party Content. You or a third party
                  licensor, as appropriate, retain all copyrights to User
                  Submissions and you are responsible for protecting those
                  rights as appropriate. You irrevocably grant us a world-wide,
                  non-exclusive, royalty-free, perpetual, non-cancelable,
                  sub-licenseable license to reproduce, publicly perform,
                  publicly display, distribute, adapt, modify, publish,
                  translate and create derivative works of User Submissions for
                  any purpose, including without limitation any purpose
                  contemplated by the Website and these Terms. Furthermore, you
                  also grant other users of the Website a right and license to
                  display, stream and download User Submissions in connection
                  with their use of the Website and for other personal use. You
                  also irrevocably waive and cause to be waived against us and
                  any of our users any claims and assertions of moral rights or
                  attribution with respect to User Submissions. You represent
                  and warrant that you have all the rights, power and authority
                  necessary to grant the rights granted herein to User
                  Submissions. Specifically, you represent and warrant that you
                  own the title to the User Submissions, that you have the right
                  to upload the User Submissions to the Website, and that
                  uploading the User Submissions will not infringe upon any
                  other party’s rights or your contractual obligations to other
                  parties. You acknowledge that we may at our sole discretion
                  refuse to publish, remove, or block access to any User
                  Submission for any reason, or for no reason at all, with or
                  without notice. Without limiting the other indemnification
                  provisions herein, you agree to defend us against any claim,
                  demand, suit or proceeding made or brought against us by a
                  third-party alleging that your User Submissions or your use of
                  the Website in violation of these Terms infringes or
                  misappropriates the intellectual property rights of a
                  third-party or violates applicable law and you shall indemnify
                  us for any damages finally awarded against and for reasonable
                  attorney’s fees incurred by us in connection with any such
                  claim, demand, suit or proceeding.
                </p>
                <p className="mb-3">
                  <strong>Content on the Website</strong>
                </p>
                <p>
                  You understand and acknowledge that, when using the Website,
                  you will be exposed to Content from a variety of sources
                  including content made available on the Website by other users
                  and through automated or other means (collectively, “Third
                  Party Content”) and that we do not control and are not
                  responsible for any Third Party Content. You understand and
                  acknowledge that you may be exposed to Content that is
                  inaccurate, offensive, indecent or otherwise objectionable or
                  may cause harm to your computer systems and, without limiting
                  the other limitation of liability provisions herein, you agree
                  to waive, and hereby do waive, any legal or equitable rights
                  or remedies you may have against us with respect thereto. We
                  claim no ownership or control over Third Party Content. Third
                  parties retain all rights to Third Party Content and they are
                  responsible for protecting their rights as appropriate. You
                  understand and acknowledge that we assume no responsibility
                  whatsoever for monitoring the Website for inappropriate
                  Content or conduct. If at any time we choose, in our sole
                  discretion, to monitor such Content, we assume no
                  responsibility for such Content, have no obligation to modify
                  or remove any such Content (including User Submissions and
                  Third Party Content), and assume no responsibility for the
                  conduct of others submitting any such Content (including User
                  Submissions and Third Party Content). Without limiting the
                  provisions below on limitations of liability and disclaimers
                  of warranties, all Content (including User Submissions and
                  Third Party Content) on the Website is provided to you “AS-IS”
                  for your information and personal use only and you shall not
                  use, copy, reproduce, distribute, transmit, broadcast,
                  display, sell, license or otherwise exploit for any other
                  purpose whatsoever that Content without the prior written
                  consent of the respective owners/licensors of the Content. You
                  acknowledge that we may at our sole discretion refuse to
                  publish, remove, or block access to any Content for any
                  reason, or for no reason at all, with or without notice.
                </p>
                <p className="mb-3">
                  <strong>User Conduct</strong>
                </p>
                <p>
                  You represent and warrant that all the information and content
                  provided by you to us is accurate and current and that you
                  have all necessary rights, power and authority to <br />
                  (i) agree to these Terms, <br />
                  (ii) provide the User Submissions to us, and <br />
                  (iii) perform the acts required of you under these Terms. As a
                  condition of your use of the Website: <br />
                  (a) You agree not to use the Website for any unlawful purpose
                  or in any way that is prohibited by these Terms;
                  <br />
                  (b) You agree to abide by all applicable local, state,
                  national and international laws and regulations; <br />
                  (c) You agree not to use the Website in any way that exposes
                  us to criminal or civil liability; <br />
                  (d) You agree that you are solely responsible for all acts and
                  omissions that occur as a result of your use of the Website;{" "}
                  <br />
                  (e) You agree that all your User Submissions that you provide
                  to us belongs to you and that you have the right and authority
                  to provide it to us; <br />
                  (f) You agree to maintain the security of your login password
                  and to be fully responsible for any and all use of your
                  account; <br />
                  (g) You agree not to use or attempt to use any other party’s
                  account on the Website without authorization; <br />
                  (h) You agree not to use any automated means, including
                  robots, crawlers or data mining tools, to download, monitor or
                  use data or Content from the Website; <br />
                  (i) You agree not to use the Website to collect usernames
                  and/or e-mail addresses for sending unsolicited messages of
                  any kind; <br />
                  (j) You agree not to take any action that imposes, or may
                  impose, in our sole discretion, an unreasonable or
                  disproportionately large load on our technology infrastructure
                  or otherwise make excessive demands on it; <br />
                  (k) You agree not to “stalk” or otherwise harass anyone on or
                  through the Website; <br />
                  (l) You agree not to forge headers or otherwise manipulate
                  identifiers in order to disguise the origin of any information
                  you transmit; <br />
                  (m) You agree not to disable, circumvent, or otherwise
                  interfere with security related features of the Website or
                  features that prevent or restrict use or copying of any
                  content or which enforce limitations on the use of the Website
                  or the content therein; <br />
                  (n) You agree not to upload, post, link to, or otherwise make
                  available on the Website any material that contains software
                  viruses or any computer code, file or program designed to
                  interrupt, destroy, limit or monitor the functionality of any
                  computer software or hardware or any telecommunications
                  equipment; <br />
                  (o) You agree not to license, sublicense, sell, resell,
                  transfer, assign, distribute or otherwise in any way
                  commercially exploit or make available the Website or any
                  Content to any third party; <br />
                  (p) You agree not to “frame” or “mirror” the Website; <br />
                  (q) You agree not to reverse engineer any portion of the
                  Website; <br />
                  (r) You agree not to link to the Website if we request that
                  you not do so; <br />
                  (s) You agree not to provide User Submissions in contravention
                  of the restrictions of Section 4 hereof; and <br />
                  (t) You agree not to export, re-export, or permit downloads of
                  any content in violation of applicable export or import laws
                  without all required approvals, licenses and exemptions. The
                  above list is only a representative list of conduct prohibited
                  on the Website, and we reserve the right to prohibit any other
                  conduct we, in our sole discretion, determine to be
                  inappropriate. We reserve the right to take appropriate action
                  against any user for any unauthorized use of the Website,
                  including civil, criminal and injunctive redress and the
                  termination of any user’s use of the Website. We also reserve
                  the right to report any suspected unauthorized use of the
                  Website to law enforcement officials, regulators or third
                  parties and to disclose any information necessary or
                  appropriate to such persons or entities relating to you. Any
                  use of the Website and our computer systems not authorized by
                  these Terms is a violation of these Terms and certain
                  international, foreign and domestic criminal and civil laws.
                </p>
                <p className="mb-3">
                  <strong>Prohibited Activities</strong>
                </p>
                <p>
                  In addition to the other restrictions outlined in these Terms,
                  you agree that you will not: violate or encourage others to
                  violate any applicable law; use content obtained from
                  SaveFiles or via the Services for commercial purposes; assign,
                  sublicense, sell, lease or otherwise transfer or convey your
                  rights under these Terms; violate or attempt to violate any
                  security feature of the Services; damage, disable, overburden
                  or impair the Services or interfere or attempt to interfere
                  with service to any user, host, or network, including, without
                  limitation, by means of submitting a virus to the Services,
                  overloading, "flooding," "spamming," "mail bombing," or
                  "crashing;" modify, reverse-engineer, decompile, disassemble,
                  or otherwise reduce or attempt to reduce to a
                  human-perceivable form any of the hidden scripting or source
                  code SaveFiles uses to provide the Services; modify, copy,
                  distribute, transmit, display, perform, reproduce, publish,
                  rent, lease, loan, license, or create derivative works based
                  on the Services except by using functionality provided by
                  SaveFiles; engage in the practices of "screen scraping,"
                  "database scraping," or any other activity with the purpose of
                  obtaining lists of users or other information from the
                  Service; harm minors in any way or solicit personal
                  information from or about a minor; impersonate any person or
                  entity, or falsely state or otherwise misrepresent your
                  affiliation with a person or entity; upload, download, post,
                  email, transmit or otherwise make available any materials that
                  contain software viruses or any other computer code, files or
                  programs designed to interrupt, destroy or limit the
                  functionality of any computer software or hardware or
                  telecommunications equipment; post unsolicited or unauthorized
                  advertising, promotional materials, "junk mail," "spam,"
                  "chain letters," "pyramid schemes," or any other form of
                  solicitation; or infringe any patent, trademark, service mark,
                  trade secret, copyright or other proprietary rights of any
                  party. You are solely responsible for any violations of any
                  relevant laws and for any infringements of third party rights
                  caused by any content you provide or transmit, or that is
                  provided or transmitted using your account. The burden of
                  proving that any content does not violate any laws or third
                  party rights rests solely with you.
                </p>
                <p className="mb-3">
                  <strong>Alternative Access</strong>
                </p>
                <p>
                  We may, from time to time in our sole discretion, provide
                  additional features and functionality that may allow you to
                  access the Content other than through the Website (ie, through
                  a desktop application or mobile application). Your use of such
                  additional features and functionality shall be governed by
                  these Terms as though you were using the Website.
                </p>
                <p className="mb-3">
                  <strong>Privacy Policy</strong>
                </p>
                <p>
                  We retain a separate Privacy Policy and your assent to these
                  Terms also signifies your assent to the Privacy Policy. We
                  reserve the right to amend the Privacy Policy at any time by
                  posting such amendments to the Website. No other notification
                  may be made to you about any amendments. Your continued use of
                  the Website following such amendments will constitute your
                  acceptance of such amendments, regardless of whether you have
                  actually read them.
                </p>
                <p className="mb-3">
                  <strong>Copyright Claims</strong>
                </p>
                <p>
                  We respect the intellectual property rights of others. You may
                  not infringe the copyright, trademark or other proprietary
                  informational rights of any party. We may in our sole
                  discretion remove any Content we have reason to believe
                  violates any of the intellectual property rights of others and
                  may terminate your use of the Website if you submit any such
                  Content. REPEAT INFRINGER POLICY. AS PART OF OUR
                  REPEAT-INFRINGEMENT POLICY, ANY USER FOR WHOSE MATERIAL WE
                  RECEIVE THREE GOOD-FAITH AND EFFECTIVE COMPLAINTS WITHIN ANY
                  CONTIGUOUS SIX-MONTH PERIOD WILL HAVE HIS GRANT OF USE OF THE
                  WEBSITE TERMINATED. We comply with the Digital Millennium
                  Copyright Act. Pursuant to Title 17, Section 512(c)(2) of the
                  United States Code, if you believe that any of your
                  copyrighted material is being infringed on the Website, we
                  have designated an agent to receive notifications of claimed
                  copyright infringement. Notifications should be e-mailed to
                  dmca@savefiles.com All notifications not relevant to us or
                  ineffective under the law will receive no response or action
                  thereupon. Pursuant to Title 17, Section 512(c)(3) of the
                  United States Code, an effective notification of claimed
                  infringement must be a written communication to our agent that
                  includes substantially the following: <br />
                  (a) Identification of the copyrighted work that is believed to
                  be infringed. Please describe the work and, where possible,
                  include a copy or the location (e.g., a URL) of an authorized
                  version of the work; <br />
                  (b) Identification of the material that is believed to be
                  infringing and its location. Please describe the material and
                  provide a URL or any other pertinent information that will
                  allow us to locate the material on the Website; <br />
                  (c) Information that will allow us to contact you, including
                  your address, telephone number and, if available, your e-mail
                  address; <br />
                  (d) A statement that you have a good faith belief that the use
                  of the material complained of is not authorized by you, your
                  agent or the law; <br />
                  (e) A statement that the information in the notification is
                  accurate and that under penalty of perjury that you are the
                  owner or are authorized to act on behalf of the owner of the
                  work that is allegedly infringed; and <br />
                  (f) A physical or electronic signature from the copyright
                  holder or an authorized representative. See our Copyright
                  Policy.
                </p>
                <p className="mb-3">
                  <strong>Modification of These Terms</strong>
                </p>
                <p>
                  We reserve the right to amend these Terms at any time by
                  posting such amended Terms to the Website. No other
                  notification may be made to you about any amendments. YOU
                  ACKNOWLEDGE THAT YOUR CONTINUED USE OF THE WEBSITE FOLLOWING
                  SUCH AMENDMENTS WILL CONSTITUTE YOUR ACCEPTANCE OF SUCH
                  AMENDMENTS, REGARDLESS OF WHETHER YOU HAVE ACTUALLY READ THEM.
                </p>
                <p className="mb-3">
                  <strong>Indemnification and Release</strong>
                </p>
                <p>
                  You hereby agree to indemnify us and hold us harmless from any
                  and all third-party claims and expenses, including attorney’s
                  fees, arising from your use of the Website or from your breach
                  of these Terms. In the event that you have a dispute with one
                  of more other users or any third parties, you hereby release
                  us, our officers, employees, agents and successors-in-right
                  from claims, demands and damages (actual and consequential) of
                  every kind or nature, known and unknown, suspected and
                  unsuspected, disclosed and undisclosed, arising out of or in
                  any way related to such disputes and/or the Website. ALL
                  CLAIMS MUST BE BROUGHT IN EACH PARTY’S INDIVIDUAL CAPACITY,
                  AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                  OR REPRESENTATIVE PROCEEDING AND THE ARBITRATOR MAY NOT
                  CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS. YOU UNDERSTAND AND
                  AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND STREAMTAPE
                  ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
                  PARTICIPATE IN A CLASS ACTION.
                </p>
                <p className="mb-3">
                  <strong>
                    Disclaimer of Warranties and Limitations of Liabilities
                  </strong>
                </p>
                <p>
                  READ THIS SECTION CAREFULLY AS IT LIMITS OUR LIABILITY TO THE
                  MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. The Website may
                  contain links to third-party websites which are independent of
                  us. We assume no responsibility for the content, privacy
                  policies, or practices of and make no representation or
                  warranty as to the accuracy, completeness or authenticity of
                  information contained in any third party websites. We have no
                  right or ability to edit the content of any third party
                  websites. You acknowledge that we shall not be liable for any
                  and all liability arising from your use of any third party
                  websites. The Website is provided “AS-IS” and without any
                  warranty or condition, express, implied or statutory. We
                  specifically disclaim to the fullest extent any implied
                  warranties of merchantability, fitness for a particular
                  purpose, non-infringement, information accuracy, integration,
                  interoperability or quiet enjoyment. We disclaim any
                  warranties for viruses or other harmful components in
                  connection with the Website. Some jurisdictions do not allow
                  the disclaimer of implied warranties. In such jurisdictions,
                  some of the foregoing disclaimers may not apply to you insofar
                  as they relate to implied warranties. UNDER NO CIRCUMSTANCES
                  SHALL WE BE LIABLE FOR DIRECT, INDIRECT INCIDENTAL, SPECIAL,
                  CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF WE HAVE BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM ANY
                  ASPECT OF YOUR USE OF THE WEBSITE. WHETHER SUCH DAMAGES ARISE
                  FROM <br />
                  (i) YOUR USE, MISUSE OR INABILITY TO USE THE WEBSITE, <br />
                  (ii) YOUR RELIANCE ON ANY CONTENT ON THE WEBSITE, <br />
                  (iii) THE INTERRUPTION, SUSPENSION, MODIFICATION, ALTERATION
                  OR COMPLETE DISCONTINUANCE OF THE WEBSITE OR <br />
                  (iv) THE TERMINATION OF SERVICE BY US. THESE LIMITATIONS ALSO
                  APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF OTHER
                  SERVICES OR PRODUCTS RECEIVED OR ADVERTISED IN CONNECTION WITH
                  THE WEBSITE. SOME JURISDICTIONS DO NOT ALLOW SOME LIMITATIONS
                  OF LIABILITY. IN SUCH JURISDICTIONS, SOME OF THE FOREGOING
                  LIMITATIONS MAY NOT APPLY TO YOU. WE DO NOT WARRANT THAT
                  <br /> (i) THE WEBSITE WILL MEET YOUR REQUIREMENTS OR
                  EXPECTATIONS, <br />
                  (ii) THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                  ERROR-FREE, <br />
                  (iii) THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF THE
                  WEBSITE WILL BE ACCURATE OR RELIABLE, <br />
                  (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION,
                  CONTENT OR OTHER MATERIAL OBTAINED THROUGH THE WEBSITE WILL
                  MEET YOUR REQUIREMENTS OR EXPECTATIONS, OR <br />
                  (v) ANY ERRORS IN CONTENT WILL BE CORRECTED. ANY CONTENT
                  OBTAINED THROUGH THE USE OF THE WEBSITE IS OBTAINED AT YOUR
                  OWN DISCRETION AND RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY
                  DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA
                  THAT RESULTS FROM SUCH CONTENT. YOUR SOLE AND EXCLUSIVE RIGHT
                  AND REMEDY IN CASE OF DISSATISFACTION WITH THE WEBSITE OR ANY
                  OTHER GRIEVANCE SHALL BE THE TERMINATION OF YOUR USE OF THE
                  WEBSITE. IN NO CASE SHALL THE MAXIMUM LIABILITY OF US ARISING
                  FROM OR RELATING TO YOUR USE OF THE WEBSITE EXCEED THE AMOUNT
                  PAID BY YOU TO US PURSUANT TO THESE TERMS.
                </p>
                <p className="mb-3">
                  <strong>Choice of Law and Venue</strong>
                </p>
                <p>
                  To the maximum extent permitted by law, these Terms as well as
                  any claim, cause of action, or dispute that may arise between
                  you and us, are governed by the laws of the United States
                  without regard to conflict of law provisions. FOR ANY CLAIM
                  BROUGHT BY EITHER PARTY, YOU AGREE TO SUBMIT AND CONSENT TO
                  THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE
                  VENUE OF, THE COURTS LOCATED IN USA.
                </p>
                <p className="mb-3">
                  <strong>General Terms</strong>
                </p>
                <p>
                  (a) These Terms, as amended from time to time, constitute the
                  entire agreement between you and us and supersede all prior
                  agreements between you and us and may not be modified without
                  our written consent. <br />
                  (b) Our failure to enforce any provision of these Terms will
                  not be construed as a waiver of any provision or right.
                  <br />
                  (c) If any part of these Terms is determined to be invalid or
                  unenforceable pursuant to applicable law, then the invalid and
                  unenforceable provision will be deemed superseded by a valid,
                  enforceable provision that most closely matches the intent of
                  the original provision and the remainder of the agreement
                  shall continue in effect. <br />
                  (d) Nothing herein is intended, nor will be deemed, to confer
                  rights or remedies upon any third party. <br />
                  (e) These Terms are not assignable, transferable or
                  sub-licensable by you except with our prior written consent,
                  but may be assigned or transferred by us without restriction.{" "}
                  <br />
                  (f) You agree that we may provide you with notices by e-mail,
                  regular mail, or postings to the Website. <br />
                  (g) The section titles in these Terms are for convenience only
                  and have no legal or contractual effect. <br />
                  (h) As used in these Terms, the term “including” is
                  illustrative and not limitative.
                </p>
                <p className="mb-3">
                  <strong>Refunds</strong>
                </p>
                <p>
                  Unless otherwise provided by United States law or by a
                  particular service offer, all purchases are final and
                  non-refundable. If you believe that SaveFiles has charged you
                  in error, you must contact us within 30 days of such charge.
                  No refunds will be given for any charges more than 30 days
                  old. We reserve the right to issue refunds or credits at our
                  sole discretion. If we issue a refund or credit, we are under
                  no obligation to issue the same or similar refund in the
                  future. This refund policy does not affect any statutory
                  rights that may apply. If you have made a payment by mistake
                  and have not used the subscription plan services, you must
                  contact admin@savefiles.com within 24 hours. This will be
                  acknowledged promptly and answered within 7 days.
                </p>
                <p className="mb-3">
                  <strong>Recurring Paid Subscriptions</strong>
                </p>
                <p>
                  Recurring subscriptions will renew indefinitely, either
                  monthly or annually, based upon your chosen subscription
                  period, unless the subscription is canceled prior to a renewal
                  date. Any other recurring subscription will renew on the same
                  day of the month as it was established, except in cases where
                  the day is not available due to a short month, in which case
                  the renewal date will be moved to the first day of the
                  following month.
                </p>
                <p className="mb-3">
                  <strong>Cancellation of Recurring Paid Subscriptions</strong>
                </p>
                <p>
                  Any other recurring subscription should be canceled by
                  navigating to https://savefiles.com/dashboard in your browser
                  while you are logged into your account and selecting the
                  option to cancel your subscription. Any payments processed
                  after an effective subscription cancellation will be promptly
                  refunded by us. If you cancel a paid subscription, but you
                  maintain your SaveFiles account as a free account, access to
                  your account may be restricted or blocked if the level of use
                  is above the limits applying to free accounts at that time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;
