// src/features/auth/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login, register, requestOtpService, verifytOtpService, resetPasswordService } from '../../services/authService';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await login(email, password);
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status == 401) {
        return rejectWithValue('Invalid email or password');
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async ({ name, email, password }, { rejectWithValue }) => {
    try {
      const response = await register(name, email, password);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const requestOtp = createAsyncThunk(
  'auth/requestOtp',
  async (data, { rejectWithValue }) => {
    try {
      const response = await requestOtpService(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async (data, { rejectWithValue }) => {
    try {
      const response = await verifytOtpService(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await resetPasswordService(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token') || null,
    isAuthenticated: !!localStorage.getItem('token'),
    user: null,
    status: 'idle',
    registerError: '',
    error: null,
    requestOtpStatus: 'idle',
    resetPasswordOtp: '',
    resetPasswordtoken: '',
    verifyOtpStatus: 'idle',
    resetPasswordStatus: 'idle',
    registerUserStatus: 'idle'
  },
  reducers: {
    resetLoginStatus: (state) => {
      state.status = 'idle';
      state.error = null
    },
    logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      state.registerUserStatus = 'idle'
      state.error = null
      state.registerError = ''
      localStorage.removeItem('token');
    },
    resetResetPasswordState: (state) => {
      state.requestOtpStatus = 'idle';
      state.resetPasswordOtp = '';
      state.resetPasswordtoken = '';
      state.verifyOtpStatus = 'idle';
      state.resetPasswordStatus = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        localStorage.setItem('token', action.payload.accessToken);
        state.token = action.payload.accessToken;
        state.user = {
          name: action.payload.name,
          email: action.payload.email
        }
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.registerUserStatus = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.registerUserStatus = 'succeeded';
        state.token = action.payload.data.accessToken;
        state.isAuthenticated = true;
        state.user = {
          name: action.payload.data.name,
          email: action.payload.data.email
        }
        localStorage.setItem('token', action.payload.accessToken);
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registerUserStatus = 'failed';
        state.registerError = action.payload.message;
      })
      .addCase(requestOtp.pending, (state) => {
        state.requestOtpStatus = 'loading';
      })
      .addCase(requestOtp.fulfilled, (state, action) => {
        state.requestOtpStatus = 'succeeded';
        state.resetPasswordtoken = action.payload.data
      })
      .addCase(requestOtp.rejected, (state, action) => {
        state.requestOtpStatus = 'failed';
      })
      .addCase(verifyOtp.pending, (state) => {
        state.verifyOtpStatus = 'loading';
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.verifyOtpStatus = 'succeeded';
        } else {
          state.verifyOtpStatus = 'failed';
        }
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.verifyOtpStatus = 'failed';
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordStatus = 'loading';
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.resetPasswordStatus = 'succeeded';
        } else {
          state.resetPasswordStatus = 'failed';
        }
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordStatus = 'failed';
      })
  }
});

export const { logout, resetResetPasswordState, resetLoginStatus } = authSlice.actions;

export default authSlice.reducer;
