import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getIpAddress } from "../../services/ipService";

export const getIpAddressService = createAsyncThunk("ip/getInfo", async (_, {rejectWithValue}) => {
    try{    
        const response = await getIpAddress();
        return response.data;
    }catch(err){
        return rejectWithValue(err.message);
    }
})

const state = {
    ipInfoData: null,
    ipInfoDataStatus: 'idle'
}

const ipInfoSlice = createSlice({
    name: "ipInfo",
    initialState: state,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(getIpAddressService.pending, (state, action) => {
            state.ipInfoDataStatus = 'loading';
        })
        .addCase(getIpAddressService.fulfilled, (state, action) => {
            state.ipInfoDataStatus = 'succeeded';
            state.ipInfoData = action.payload;
        })
        .addCase(getIpAddressService.rejected, () => {
            state.ipInfoDataStatus = 'failed';
        })
    }
})

export default ipInfoSlice.reducer;

