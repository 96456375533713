import React from "react";
import "./Upload.css";
import { Link } from "react-router-dom";

const Upload = () => {
  return (
    <>
      <div
        className="modal fade"
        id="videomodal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Upload
              </h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              <div className="modal-box">
                <div className="upload-link">
                  <div className="upload-form-sec">
                    <form>
                      <div className="mb-4 file-upload-box position-relative">
                        <label htmlFor="formFile" className="form-label d-none">
                          Drag & Drop your files or Browse
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          id="formFile"
                          placeholder="Drag & Drop your files or Browse"
                        />
                        <span className="upload-btn">
                          Drag & Drop your files or Browse
                        </span>
                        {/* <div className="upload-file"></div> */}
                      </div>
                      <ul className="btn-modal d-flex align-content-center justify-content-end flex-wrap">
                        <li>
                          <Link to={'/signup'}>
                            <button type="button" data-bs-dismiss="modal" className="btn btn-grey">
                                Create an Account
                            </button>
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-close"
                            data-bs-dismiss="modal">
                            Close
                          </button>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upload;
