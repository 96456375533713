// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets//images/contact-home.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.home-contact-sec {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  
    h3 {
        font-family: var(--font_Poppins);
        font-size: 32px;
        font-weight: 500;
        line-height: 48px;

        @media screen and (max-width: 1199px) {
            font-size: 26px;
            line-height: 37px;

            @media screen and (max-width: 991px) {
                font-size: 22px;
                line-height: 29px;
                
                @media screen and (max-width: 767px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
      }
}



  
  `, "",{"version":3,"sources":["webpack://./src/Components/ContactUsFooterTop/ContactUsFooterTop.css"],"names":[],"mappings":";AACA;IACI,mDAAsD;IACtD,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,4BAA4B;IAC5B,2BAA2B;;IAE3B;QACI,gCAAgC;QAChC,eAAe;QACf,gBAAgB;QAChB,iBAAiB;;QAEjB;YACI,eAAe;YACf,iBAAiB;;YAEjB;gBACI,eAAe;gBACf,iBAAiB;;gBAEjB;oBACI,eAAe;oBACf,iBAAiB;gBACrB;YACJ;QACJ;MACF;AACN","sourcesContent":["\n.home-contact-sec {\n    background: url(../../assets//images/contact-home.png);\n    width: 100%;\n    height: 100%;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n  \n    h3 {\n        font-family: var(--font_Poppins);\n        font-size: 32px;\n        font-weight: 500;\n        line-height: 48px;\n\n        @media screen and (max-width: 1199px) {\n            font-size: 26px;\n            line-height: 37px;\n\n            @media screen and (max-width: 991px) {\n                font-size: 22px;\n                line-height: 29px;\n                \n                @media screen and (max-width: 767px) {\n                    font-size: 18px;\n                    line-height: 24px;\n                }\n            }\n        }\n      }\n}\n\n\n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
