import axios from "axios";

export const getDirectory = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Not able to get the token");
  }
  try {
    const response = await axios.get(`${url}/user/dir`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        parentId: data.parentId === "" ? "" : data.parentId,
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      // Handle 401 here, e.g., redirect to login
      localStorage.removeItem('token')
      window.location.href = '/signin'; // Example redirection to login page
    } else {
      console.error('Error:', error.message);
    }
  }
};

export const createDirectory = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Not able to get the token");
  }

  const response = await axios.post(`${url}/user/dir`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteDirectory = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }

  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Not able to get the token");
  }

  const response = await axios.delete(`${url}/user/dir/${data.dirId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const updateDirectory = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }

  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Not able to get the token");
  }

  const info = {
    name: data.name,
    parentId: data.parentId,
  };

  const response = await axios.put(`${url}/user/dir/${data.dirId}`, info, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const renameFileService = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }

  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Not able to get the token");
  }

  const info = {
    id: data.id,
    newFileName: data.newFileName,
  };

  const response = await axios.post(`${url}/user/renameFile/${data.id}`, info, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

export const deleteFileService = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }

  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Not able to get the token");
  }

  const response = await axios.delete(`${url}/user/delete-multiple-files`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return response;
};

export const videoViewCountService = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  if (!url) {
    throw new Error("Not able to find the url");
  }

  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  const id = data.id;
  const info = {
    region: {
      IP: data.ip,
      country: data.country,
      code: data.code,
    },
  };

  const response = await axios.post(`${url}/user/views/${id}`, info, {
    headers,
  });

  return response;
};

export const getFileUrlService = async (data) => {

  try {
    const url = process.env.REACT_APP_API_URL;
    if (!url) {
      throw new Error("Not able to find the url");
    }

    const response = await axios.get(`${url}/user/getFileUrl/${data}`);

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      // Handle 401 here, e.g., redirect to login
      localStorage.removeItem('token')
      window.location.href = '/signin'; // Example redirection to login page
    } else {
      console.error('Error:', error.message);
    }
  }
};

export const getFileUrlServiceV2 = async (data) => {

  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }

  const response = await axios.get(`${url}/user/getFileUrlV2?filename=${data.fileName}&key=${data.id}`);

  return response;
};
export const getInactiveFilesService = async () => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Not able to get the token");
  }

  try {

    const response = await axios.get(`${url}/user/get-inactive-files`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      // Handle 401 here, e.g., redirect to login
      localStorage.removeItem('token')
      window.location.href = '/signin'; // Example redirection to login page
    } else {
      console.error('Error:', error.message);
    }
  }
};

export const moveFilesService = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Not able to get the token");
  }

  const response = await axios.put(`${url}/user/move-files`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getFileDetails = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }
  const response = await axios.get(`${url}/user/getFileDetails/${data}`);
  // const response = await axios.get(`${url}/user/getFileDetails/kjhsvd354sdc3`);
  return response;
}

export const getSignedUrl = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }
  // const response = await axios.get(`${url}/user/getFileUrlV2?filename=${data}`);  
  // return response;
}

export const getThumbnail = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }
  try {
    const response = await axios.get(`${url}/user/getFileUrlV2?filename=${data}`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      // Handle 401 here, e.g., redirect to login
      localStorage.removeItem('token')
      window.location.href = '/signin'; // Example redirection to login page
    } else {
      console.error('Error:', error.message);
    }
  }
}

export const getSubtitle = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }
  try {
    const response = await axios.get(`${url}/user/getFileUrlV2?filename=${data}`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized! Redirecting to login...');
      // Handle 401 here, e.g., redirect to login
      localStorage.removeItem('token')
      window.location.href = '/signin'; // Example redirection to login page
    } else {
      console.error('Error:', error.message);
    }
  }
}

export const getOtherFileDetail = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error("Not able to find the url");
  }

  const response = await axios.get(`${url}/user/getFileUrl/${data}`);

  return response;
};