import React from "react";
import "./FeatureStyle.css";

const Feature = () => {
  return (
    <>

    <section className="features-section p-100 position-relative">
        <div className="container">
            <hr />
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12">
              <div className="heading-head text-center">
                <span className="sub-heading">Our Features</span>
                <h2>
                Explore the tools that make this the best file-sharing software
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-lg-4 col-md-6 mb-4'>
                <div className="card-smiple h-100 card-smiple-left aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000} data-aos-once="true">
                    <div className="d-flex">
                    <div className="flex-grow-1 text-left pe-lg-3">
                        <div className='icons-box d-flex justify-content-center align-items-center'>
                            <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 9.5625V13.8281L6.1875 16.5H14.8125L19.5 13.8281V9.5625L15.375 11.9062L15.1875 12H15H6H5.8125L5.625 11.9062L1.5 9.5625ZM1.5 7.82812L6.1875 10.5H14.8125L19.5 7.82812V4.59375L14.5781 1.5H6.42188L1.5 4.59375V7.82812ZM1.5 19.4062L6.42188 22.5H14.5781L19.5 19.4062V15.5625L15.375 17.9062L15.1875 18H15H6H5.8125L5.625 17.9062L1.5 15.5625V19.4062ZM6 0H15L21 3.75V20.25L15 24H6L0 20.25V3.75L6 0Z" fill="#FBFDFF"/>
                            </svg>
                        </div>
                        <h5 className="card-smiple-title">Get 200 GB Free</h5>
                        <p className="m-0">Enjoy a generous 200 GB of free storage for your files.</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4'>
                <div className="card-smiple h-100 card-smiple-left aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000} data-aos-once="true">
                    <div className="d-flex">
                    <div className="flex-grow-1 text-left pe-lg-3">
                        <div className='icons-box d-flex justify-content-center align-items-center'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 1.5C10.0938 1.53125 8.34375 2 6.75 2.90625C5.15625 3.84375 3.875 5.125 2.90625 6.75C1.96875 8.40625 1.5 10.1562 1.5 12C1.5 13.8438 1.96875 15.5938 2.90625 17.25C3.875 18.875 5.15625 20.1562 6.75 21.0938C8.34375 22 10.0938 22.4688 12 22.5C13.9062 22.4688 15.6562 22 17.25 21.0938C18.8438 20.1562 20.125 18.875 21.0938 17.25C22.0312 15.5938 22.5 13.8438 22.5 12C22.5 10.1562 22.0312 8.40625 21.0938 6.75C20.125 5.125 18.8438 3.84375 17.25 2.90625C15.6562 2 13.9062 1.53125 12 1.5ZM12 24C9.8125 23.9688 7.8125 23.4375 6 22.4062C4.1875 21.3438 2.71875 19.875 1.59375 18C0.53125 16.0938 0 14.0938 0 12C0 9.90625 0.53125 7.90625 1.59375 6C2.71875 4.125 4.1875 2.65625 6 1.59375C7.8125 0.5625 9.8125 0.03125 12 0C14.1875 0.03125 16.1875 0.5625 18 1.59375C19.8125 2.65625 21.2812 4.125 22.4062 6C23.4688 7.90625 24 9.90625 24 12C24 14.0938 23.4688 16.0938 22.4062 18C21.2812 19.875 19.8125 21.3438 18 22.4062C16.1875 23.4375 14.1875 23.9688 12 24ZM13.5 9H7.5V15H13.5V13.875V10.125V9ZM15 9.5625L18 8.4375L19.5 7.875V9.46875V14.5312V16.125L18 15.5625L15 14.4375V15V16.5H13.5H7.5H6V15V9V7.5H7.5H13.5H15V9V9.5625ZM15 11.1562V12.8438L18 13.9688V10.0312L15 11.1562Z" fill="white"/>
                            </svg>
                        </div>
                        <h5 className="card-smiple-title">High-Quality Playback</h5>
                        <p className="m-0">Experience high-quality playback of videos and media files.</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4'>
                <div className="card-smiple h-100 card-smiple-left aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000} data-aos-once="true">
                    <div className="d-flex">
                    <div className="flex-grow-1 text-left pe-lg-3">
                        <div className='icons-box d-flex justify-content-center align-items-center'>
                            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.75 11.25V13.5H18.8906L24.8906 7.5L18.8906 1.5H18.75V3.75V5.25H17.25H13.5C11.8125 5.28125 10.3906 5.85938 9.23438 6.98438C8.10938 8.14062 7.53125 9.5625 7.5 11.25C7.5625 12.5938 8.0625 13.6875 9 14.5312C9.15625 13.1562 9.70312 12.0312 10.6406 11.1562C11.6094 10.25 12.7969 9.78125 14.2031 9.75H17.25H18.75V11.25ZM12 18C11.9688 18 11.7031 17.8906 11.2031 17.6719C10.6719 17.4844 10.0469 17.1562 9.32812 16.6875C8.48438 16.1562 7.73438 15.4531 7.07812 14.5781C6.39062 13.6719 6.03125 12.5625 6 11.25C6.0625 9.125 6.79688 7.35938 8.20312 5.95312C9.60938 4.54688 11.375 3.8125 13.5 3.75H15.75H17.25V2.25V1.5V0H18.75H19.5L25.9219 6.42188L27 7.5L25.9219 8.57812L19.5 15H18.75H17.25V13.5V12.75V11.25H15.75H14.2031C13.1406 11.2812 12.2656 11.6406 11.5781 12.3281C10.8906 13.0156 10.5312 13.8906 10.5 14.9531C10.5 15.2031 10.5312 15.4531 10.5938 15.7031C10.7812 16.4219 11.0625 16.9844 11.4375 17.3906C11.7812 17.7969 11.9688 18 12 18ZM0.75 3H5.25H6V4.5H5.25H1.5V22.5H19.5V18.75V18H21V18.75V23.25V24H20.25H0.75H0V23.25V3.75V3H0.75Z" fill="white"/>
                            </svg>
                        </div>
                        <h5 className="card-smiple-title">Share Files with No Restrictions</h5>
                        <p className="m-0">Easily share your files with anyone, regardless of their location or device</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-lg-0 mb-4'>
                <div className="card-smiple h-100 card-smiple-left aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000} data-aos-once="true">
                    <div className="d-flex">
                    <div className="flex-grow-1 text-left pe-lg-3">
                        <div className='icons-box d-flex justify-content-center align-items-center'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.29688 5.39062C2.20312 6.48437 2.28125 7.79687 2.53125 9.32812C2.75 10.8594 3.20312 12.4531 3.89062 14.1094C4.57812 15.7656 5.57812 17.3281 6.89062 18.7969C8.20312 20.2344 9.90625 21.4219 12 22.3594C14.0938 21.4219 15.7969 20.2344 17.1094 18.7969C18.4219 17.3281 19.4219 15.7656 20.1094 14.1094C20.7969 12.4531 21.25 10.8594 21.4688 9.32812C21.7188 7.79687 21.8125 6.48437 21.75 5.39062L12 1.59375L2.29688 5.39062ZM23.25 5.29688H23.2031C23.2969 6.48437 23.2188 7.90625 22.9688 9.5625C22.7188 11.2188 22.2188 12.9375 21.4688 14.7188C20.7188 16.5 19.625 18.1875 18.1875 19.7812C16.75 21.4062 14.8906 22.7344 12.6094 23.7656L12 24L11.3906 23.7656C9.10938 22.7344 7.25 21.4062 5.8125 19.7812C4.375 18.1875 3.28125 16.5 2.53125 14.7188C1.78125 12.9375 1.28125 11.2188 1.03125 9.5625C0.78125 7.90625 0.703125 6.48437 0.796875 5.29688L0.84375 4.35938L1.73438 3.98438L11.4375 0.234375L12 0L12.5625 0.234375L22.2656 3.98438L23.1562 4.35938L23.25 5.29688ZM17.0156 9.51562L11.0156 15.5156L10.5 16.0781L9.98438 15.5156L6.98438 12.5156L6.42188 12L7.5 10.9219L8.01562 11.4844L10.5 13.9219L15.9844 8.48438L16.5 7.92188L17.5781 9L17.0156 9.51562Z" fill="white"/>
                            </svg>
                        </div>
                        <h5 className="card-smiple-title">Secure and Private</h5>
                        <p className="m-0">Store your files securely and kept private with server-side encryption.</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-lg-0 mb-4'>
                <div className="card-smiple h-100 card-smiple-left aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000} data-aos-once="true">
                    <div className="d-flex">
                    <div className="flex-grow-1 text-left pe-lg-3">
                        <div className='icons-box  d-flex justify-content-center align-items-center'>
                            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 1.5V3V3.75H10.25H5.75H5V3V1.5H2V22.5H14V1.5H11ZM9.5 1.5H6.5V2.25H9.5V1.5ZM0.5 0H2H14H15.5V1.5V22.5V24H14H2H0.5V22.5V1.5V0Z" fill="white"/>
                            </svg>
                        </div>
                        <h5 className="card-smiple-title">Mobile Friendly</h5>
                        <p className="m-0">Access and manage your files seamlessly on mobile devices.</p>
                    </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-6 mb-4 mb-lg-0'>
                <div className="card-smiple h-100 card-smiple-left aos-init aos-animate" data-aos="fade-up" data-aos-duration={1000} data-aos-once="true">
                    <div className="d-flex">
                    <div className="flex-grow-1 text-left pe-lg-3">
                        <div className='icons-box d-flex justify-content-center align-items-center'>
                            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2H9V20H12V2ZM9 0.5H12H13.5V2V20V21.5H12H9H7.5V20V2V0.5H9ZM4.5 11H1.5V20H4.5V11ZM1.5 9.5H4.5H6V11V20V21.5H4.5H1.5H0V20V11V9.5H1.5ZM16.5 5V20H19.5V5H16.5ZM15 3.5H16.5H19.5H21V5V20V21.5H19.5H16.5H15V20V5V3.5Z" fill="white"/>
                            </svg>
                        </div>
                        <h5 className="card-smiple-title">Advanced Analytics</h5>
                        <p className="m-0">Gain insights with advanced analytics to track file usage and performance.</p>
                    </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section> 
    
        </>
  );
};

export default Feature;
