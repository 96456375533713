// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pl {
	display: block;
	width: 6.25em;
	height: 6.25em;
}
.pl__ring, .pl__ball {
	animation: ring 2s ease-out infinite;
}
.pl__ball {
	animation-name: ball;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),10%,10%);
		--fg: hsl(var(--hue),10%,90%);
	}
}

/* Animation */
@keyframes ring {
	from {
		stroke-dasharray: 0 257 0 0 1 0 0 258;
	}
	25% {
		stroke-dasharray: 0 0 0 0 257 0 258 0;
	}
	50%, to {
		stroke-dasharray: 0 0 0 0 0 515 0 0;
	}
}
@keyframes ball {
	from, 50% {
		animation-timing-function: ease-in;
		stroke-dashoffset: 1;
	}
	64% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -109;
	}
	78% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -145;
	}
	92% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -157;
	}
	57%, 71%, 85%, 99%, to {
		animation-timing-function: ease-out;
		stroke-dashoffset: -163;
	}
}

.loader-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/Components/Loading/Loading.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,aAAa;CACb,cAAc;AACf;AACA;CACC,oCAAoC;AACrC;AACA;CACC,oBAAoB;AACrB;;AAEA,gBAAgB;AAChB;CACC;EACC,6BAA6B;EAC7B,6BAA6B;CAC9B;AACD;;AAEA,cAAc;AACd;CACC;EACC,qCAAqC;CACtC;CACA;EACC,qCAAqC;CACtC;CACA;EACC,mCAAmC;CACpC;AACD;AACA;CACC;EACC,kCAAkC;EAClC,oBAAoB;CACrB;CACA;EACC,kCAAkC;EAClC,uBAAuB;CACxB;CACA;EACC,kCAAkC;EAClC,uBAAuB;CACxB;CACA;EACC,kCAAkC;EAClC,uBAAuB;CACxB;CACA;EACC,mCAAmC;EACnC,uBAAuB;CACxB;AACD;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".pl {\n\tdisplay: block;\n\twidth: 6.25em;\n\theight: 6.25em;\n}\n.pl__ring, .pl__ball {\n\tanimation: ring 2s ease-out infinite;\n}\n.pl__ball {\n\tanimation-name: ball;\n}\n\n/* Dark theme  */\n@media (prefers-color-scheme: dark) {\n\t:root {\n\t\t--bg: hsl(var(--hue),10%,10%);\n\t\t--fg: hsl(var(--hue),10%,90%);\n\t}\n}\n\n/* Animation */\n@keyframes ring {\n\tfrom {\n\t\tstroke-dasharray: 0 257 0 0 1 0 0 258;\n\t}\n\t25% {\n\t\tstroke-dasharray: 0 0 0 0 257 0 258 0;\n\t}\n\t50%, to {\n\t\tstroke-dasharray: 0 0 0 0 0 515 0 0;\n\t}\n}\n@keyframes ball {\n\tfrom, 50% {\n\t\tanimation-timing-function: ease-in;\n\t\tstroke-dashoffset: 1;\n\t}\n\t64% {\n\t\tanimation-timing-function: ease-in;\n\t\tstroke-dashoffset: -109;\n\t}\n\t78% {\n\t\tanimation-timing-function: ease-in;\n\t\tstroke-dashoffset: -145;\n\t}\n\t92% {\n\t\tanimation-timing-function: ease-in;\n\t\tstroke-dashoffset: -157;\n\t}\n\t57%, 71%, 85%, 99%, to {\n\t\tanimation-timing-function: ease-out;\n\t\tstroke-dashoffset: -163;\n\t}\n}\n\n.loader-parent {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    height: 100vh;\n    width: 100vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
