import React from "react";
import "./SaveFiles.css";
import { MdStorage } from "react-icons/md";
import { RiMoneyDollarBoxFill } from "react-icons/ri";
import { BsCloudUpload } from "react-icons/bs";
import { FaInfinity } from "react-icons/fa";
import { AiOutlineCloudUpload } from "react-icons/ai";

const SaveFiles = () => {
  return (
    <>
      <section className="savefile-section p-100 position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="heading-head text-center">
                <span className="sub-heading"> Why choose SaveFiles?</span>
                <h2>Your ultimate file sharing destination</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="savefile-box text-center">
                <div className="icons">
                  {/* <img src="images/video_meeting.svg" alt="" /> */}
                  <MdStorage />
                </div>
                <div className="content-box">
                  <h3>Free Storage</h3>
                  <p>
                    Upload up to 200 GB of files for free. Your active files will
                    be securely stored on our protected servers
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="savefile-box text-center">
                <div className="icons">
                  {/* <img src="images/video_meeting.svg" alt="" /> */}
                  <FaInfinity />
                </div>
                <div className="content-box">
                  <h3>Unlimited Bandwidth</h3>
                  <p>
                    Stream videos anytime, anywhere, with unlimited bandwidth
                    and speed
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="savefile-box text-center">
                <div className="icons">
                  {/* <img src="images/video_meeting.svg" alt="" /> */}
                  <RiMoneyDollarBoxFill />
                </div>
                <div className="content-box">
                  <h3>Monetization Options</h3>
                  <p>
                    Share your files and earn money per 10,000 downloads or
                    streams
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="savefile-box text-center">
                <div className="icons">
                  {/* <img src="images/video_meeting.svg" alt="" /> */}
                  {/* <FaCloudUploadAlt /> */}
                  {/* <BsCloudUpload /> */}
                  <AiOutlineCloudUpload />
                </div>
                <div className="content-box">
                  <h3>Remote Upload</h3>
                  <p>
                    Conveniently mass upload files with any public accessible
                    URL
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SaveFiles;
