import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVideoSettings } from '../../features/videoSetting/videosettingSlice';
import { getFileUrlV2, getOtherFileDetails } from '../../features/files/fileSlice';
import { getFileDetails } from '../../services/fileService';
import NotFound from '../NotFound/NotFound';
import { toast } from 'react-toastify';

const DocumentViewer = () => {
    const { videoSetting } = useSelector((state) => state.videosetting)
    const { id } = useParams();
    const dispatch = useDispatch();
    const [docName, setDocName] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [fileType, setFileType] = useState("");
    const [docUrl, setDocUrl] = useState("");
    const [fileErrorFound, setFileErrorFound] = useState(false);
    const { gettedfileData } = useSelector(
        (state) => state.file
    );

    useEffect(() => {
        const fetchFileDetails = async () => {
            try {
                // Dispatch to fetch file details initially
                const fileDetails = await getFileDetails(id);
                if (fileDetails.status === 200) {
                    const data = {
                        id: id,
                        fileName: fileDetails.data.data.name
                    }
                    dispatch(getFileUrlV2(data));
                }
            } catch (error) {
                setFileErrorFound(true);
                toast.error(error?.response?.data?.message)
                console.error("Error fetching file details:", error);
            }
        };

        fetchFileDetails();
    }, [id]);

    useEffect(() => {
        dispatch(getVideoSettings());
        dispatch(getOtherFileDetails(id)).then((res) => {
            const fileName = res?.payload?.data?.fileName;
            setDocName(fileName);
            const fileExtension = fileName?.split(".").pop().toLowerCase();
            setFileType(fileExtension);
            setFileSize(res?.payload?.data?.fileSize);
        })
    }, [dispatch]);


    useEffect(() => {
        if (gettedfileData?.url !== "") {
            setDocUrl(gettedfileData?.url);
        }
    }, [gettedfileData]);

    const isSupportedInIframe = () => {
        // File types that can be embedded directly in an iframe
        return [
            "pdf",    // PDF file
            "html",  // HTML files
            "docx",  // Word documents
            "xlsx",  // Excel spreadsheets
            "csv",   // CSV files
        ].includes(fileType);
    };

    if (fileErrorFound) {
        return (
            <NotFound />
        )
    }
    return (
        <>
            <section className="image-single-sec p-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-head mb-5 text-center">
                                {/* <span className="sub-heading mb-1">Welcome Images</span> */}
                                <h2>{docName}</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="top-image">
                                {
                                    isSupportedInIframe() ? (
                                        <iframe
                                            src={docUrl}
                                            title="File Viewer"
                                            width="100%"
                                            height="600px"
                                            style={{ border: "1px solid #ccc" }}
                                        ></iframe>
                                    ) : (
                                        <p>Unsupported file type: {fileType}</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="video-btn-btm text-center mt-3">
                                {videoSetting?.videoAccessSettings?.showDownloadButton === true && (
                                    <a
                                        href={docUrl}
                                        className="btn btn-primary w-100 blue-btn"
                                        download
                                    >
                                        Download
                                    </a>
                                )}
                                {/* <p className="subheading">Size: {fileSize} MB</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DocumentViewer