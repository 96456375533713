import axios from 'axios';

export const getPlans = async () => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }

    const finalResult = await axios.get(`${url}/user/plans`)

    return finalResult
}

export const createCustomerService = async (data) => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }

    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }

    const result = await axios.post(`${url}/user/plans/create-customer`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    return result
}

export const createSubscriptionService = async (data) => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }

    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }

    const result = await axios.post(`${url}/user/plans/create-subscription`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    return result
}

export const savePaymentMethodService = async (data) => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }

    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }

    const result = await axios.post(`${url}/user/save-payment-method`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    return result
}

export const requestPayoutService = async (data) => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }

    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }

    const result = await axios.post(`${url}/user/send-payout-request`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    return result
}

export const getTransactionsService = async () => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }

    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }

    const finalResult = await axios.get(`${url}/user/get-transactions`, {
        headers: {
            'Authorization': `Bearer ${token}` 
        }
    })

    return finalResult
}

export const deativateSubscriptionService = async (data) => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }

    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }

    const result = await axios.post(`${url}/user/cancel-subscription`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    return result
}