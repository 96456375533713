import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  getPlans,
  createCustomerService,
  createSubscriptionService,
  savePaymentMethodService,
  requestPayoutService,
  getTransactionsService,
  deativateSubscriptionService
} from "../../services/planService";

export const getAllPlans = createAsyncThunk(
  "plan/allPlans",
  async (_, {rejectWithValue}) => {
    try {
      const response = await getPlans();
      return response;
    } catch (err) {
      rejectWithValue(err.message.data);
    }
  }
);

export const createCustomer = createAsyncThunk(
  "plan/createCustomer",
  async (data, {rejectWithValue}) => {
    try {
      const response = await createCustomerService(data);
      return response;
    } catch (err) {
      rejectWithValue(err.message.data);
    }
  }
);

export const savePaymentMethod = createAsyncThunk(
  "plan/savePaymentMethod",
  async (data, {rejectWithValue}) => {
    try {
      const response = await savePaymentMethodService(data);
      return response;
    } catch (err) {
      rejectWithValue(err.message.data);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "plan/createSubscription",
  async (data, {rejectWithValue}) => {
    try {
      const response = await createSubscriptionService(data);
      return response;
    } catch (err) {
      rejectWithValue(err.message.data);
    }
  }
);

export const requestPayout = createAsyncThunk(
  "plan/requestPayout",
  async (data, {rejectWithValue}) => {
    try {
      const response = await requestPayoutService(data);
      return response;
    } catch (err) {
      rejectWithValue(err.message.data);
    }
  }
);

export const getTransactions = createAsyncThunk(
  "plan/getTransactions",
  async (_, {rejectWithValue}) => {
    try {
      const response = await getTransactionsService();
      return response;
    } catch (err) {
      if (err && err.response && err.response.status == 401) {
        return rejectWithValue("Unauthorized");
      }
      return err.response.data;
    }
  }
);

export const deativateSubscription = createAsyncThunk(
  "plan/deativateSubscription",
  async (data, {rejectWithValue}) => {
    console.log('test2')
    try {
      const response = await deativateSubscriptionService(data);
      return response;
    } catch (err) {
      if (err && err.response && err.response.status == 401) {
        return rejectWithValue("Unauthorized");
      }
      return err.response.data;
    }
  }
);

const planSilce = createSlice({
  name: "planSlice",
  initialState: {
    getPlansStatus: "idle",
    monthlyPlansData: null,
    yearlyPlansData: null,
    createCustomerStatus: "idle",
    customerData: null,
    createSubscriptionStatus: "idle",
    sessionId: null,
    savePaymentMethodStatus: "idle",
    savePaymentMethodData: [],
    requestPayoutStatus: "idle",
    requestPayoutData: [],
    requestPayoutErrorMessage: "",
    getTransactionsErrorMessage: "",
    getTransactionsStatus: "idle",
    subscriptionHistoryData: [],
    deativateSubscriptionStatus: 'idle'
  },
  reducers: {
    resetValue: (state, action) => {
      state.getPlansStatus = "idle";
      state.monthlyPlansData = null;
      state.yearlyPlansData = null;
    },
    resetRequestPayoutValue: (state, action) => {
      state.requestPayoutStatus = "idle";
      state.requestPayoutData = [];
      state.requestPayoutErrorMessage = "";
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllPlans.pending, state => {
        state.getPlansStatus = "loading";
      })
      .addCase(getAllPlans.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.getPlansStatus = "succeeded";
          state.monthlyPlansData = action.payload.data.data.plans.monthly;
          state.yearlyPlansData = action.payload.data.data.plans.yearly;
        } else {
          state.getPlansStatus = "failed";
        }
      })
      .addCase(getAllPlans.rejected, state => {
        state.getPlansStatus = "failed";
      })
      .addCase(createCustomer.pending, state => {
        state.createCustomerStatus = "loading";
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.createCustomerStatus = "succeeded";
          state.customerData = action.payload.data.data;
        } else {
          state.createCustomerStatus = "failed";
        }
      })
      .addCase(createCustomer.rejected, state => {
        state.createCustomerStatus = "failed";
      })
      .addCase(createSubscription.pending, state => {
        state.createSubscriptionStatus = "loading";
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.createSubscriptionStatus = "succeeded";
          state.sessionId = action.payload.data.data;
        } else {
          state.createSubscriptionStatus = "failed";
        }
      })
      .addCase(createSubscription.rejected, state => {
        state.createSubscriptionStatus = "failed";
      })
      .addCase(savePaymentMethod.pending, state => {
        state.savePaymentMethodStatus = "loading";
      })
      .addCase(savePaymentMethod.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.savePaymentMethodStatus = "succeeded";
        } else {
          state.savePaymentMethodStatus = "failed";
        }
      })
      .addCase(savePaymentMethod.rejected, state => {
        state.savePaymentMethodStatus = "failed";
      })
      .addCase(requestPayout.pending, state => {
        state.requestPayoutStatus = "loading";
      })
      .addCase(requestPayout.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.requestPayoutStatus = "succeeded";
        } else {
          state.requestPayoutStatus = "failed";
          state.requestPayoutErrorMessage = action.payload.data.message;
        }
      })
      .addCase(requestPayout.rejected, state => {
        state.requestPayoutStatus = "failed";
        state.requestPayoutErrorMessage = null;
      })
      .addCase(getTransactions.pending, state => {
        state.getTransactionsStatus = "loading";
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.getTransactionsStatus = "succeeded";
          state.subscriptionHistoryData = action.payload.data.data
        } else {
          state.getTransactionsStatus = "failed";
          state.getTransactionsErrorMessage = action.payload.data.message;
        }
      })
      .addCase(getTransactions.rejected, state => {
        state.getTransactionsStatus = "failed";
      })
      .addCase(deativateSubscription.pending, state => {
        state.deativateSubscriptionStatus = "loading";
      })
      .addCase(deativateSubscription.fulfilled, (state, action) => {
        if (action.payload.data.success) {
          state.deativateSubscriptionStatus = "succeeded";
        } else {
          state.deativateSubscriptionStatus = "failed";
        }
      })
      .addCase(deativateSubscription.rejected, state => {
        state.deativateSubscriptionStatus = "failed";
      });
  },
});

export const {resetValue, resetRequestPayoutValue} = planSilce.actions;
export default planSilce.reducer;
