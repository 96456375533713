import axios from 'axios';

export const remoteUpload = async (data) => {

    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error('Not able to find the url');
    }
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('Not able to get the token');
    }

    const finalResult = await axios.post(`${url}/user/v2/remoteUpload`, {data : data}, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    })

    return finalResult
}