import React from 'react'
import Boxyellow from '../../assets/images/box-yellow.png'
import Bluebox from '../../assets/images/box-blue.png'
import Redbox from '../../assets/images/red-box.png'
import FileIcons from '../../assets/images/file-icons.svg'
import UploadIcons from '../../assets/images/upload-icons.svg'
import ShareIcons from '../../assets/images/share-icons.svg'
import './AboutUsStyles.css'
import ContactUSFooterTop from '../../Components/ContactUsFooterTop/ContactUsFooterTop'

const AboutUs = () => {
  return (
    <>
    <section className='login-sec p-100 position-relative'>
        <div className='banner-animation-icons'>
            <span className="yellow-box">
                <img src={Boxyellow} alt="" />
            </span>
            <span className="box-blue">
                <img src={Bluebox} alt="" />
            </span>
            <span className="red-box">
                <img src={Redbox} alt="" />
            </span>
        </div>

        <div className='copyright-sec'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-12 col-md-12 text-center'>
                        <div className="heading-head mb-5">
                            <span className="sub-heading">Know More</span>
                            <h2 className='mb-2'>About Us</h2>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='copyright-details'>
                            <p><strong>SaveFiles gives the user the best experience on a file sharing website.</strong></p>
                            <ul className='listing-savefile'>
                                <li>Diversify your followers among platforms. Any platform can go offline or delete you tomorrow, so why don’t use multiple awesome sites and let users choose their favorite?</li>
                                <li>Option to force users to disable their adblocker. No more freeloading of your great content!</li>
                                <li>Unlike the big platform (with the red logo, we don’t care how many bad words you say and do not demonetize your content)</li>
                                <li>You can choose (opt-in) to allow downloads of your videos.</li>
                                <li>We also don’t ban content creators for controversial actions. As long you act according the law, we care exactly 0% about your political opinions etc. and won’t ever delete or demonetize you!</li>
                                <li>Fast and reliable servers (unlike many other sites...)</li>
                                <li>Adult/18+ content is also allowed and fully monetizable (most video platforms don't allow that too)</li>
                                <li>Great subtitle support. Multiple languages are no problem.</li>
                                <li>No 2 preroll ads like youtube. Decide how many ads (and earnings) your users should see!</li>
                                <li>Really simple and straightforward. Upload a video, copy link and share! No complicated processes, your grandma could do it too!</li>
                                <li>Reach all markets & countries, including China!</li>
                            </ul>
                            <p className='mb-3 mt-5'><strong>With a FREE SaveFiles account you get the following features :</strong></p>
                            <ul className='following-features'>
                                <li className='d-flex flex-wrap'> <span className='img-feature'><img src={FileIcons} alt="" /></span> <span className='text-feature'>Earn Money by sharing your links</span></li>
                                <li className='d-flex flex-wrap'> <span className='img-feature'><img src={UploadIcons} alt="" /></span><span className='text-feature'>Upload files and access them from anywhere</span></li>
                                <li className='d-flex flex-wrap'> <span className='img-feature'><img src={ShareIcons} alt="" /></span><span className='text-feature'>Share the videos with anyone in the world.</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

< ContactUSFooterTop />
</>
  )
}

export default AboutUs