import React, { useState, useEffect, useRef } from "react";
import "./EmbeddedVideoPlayer.css";
import { useParams } from "react-router-dom";
import { getFileUrl, getVideoFileDetails, getVideoSignedUrl, getVideoSubtitle, getVideoThumbnail, videoViewCount } from "../../features/files/fileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getIpAddressService } from "../../features/ipInfo/ipInfo";
import { useDetectAdBlock } from "adblock-detect-react";
import Hls from "hls.js";
import axios from "axios";
import { TbSpiral } from "react-icons/tb";

const EmbeddedVideoPlayer = () => {

  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (adBlockDetected) {
      console.log('ad block detected');
    }
  }, [adBlockDetected]);
  const [videoUrl, setVideoUrl] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { gettedfileData, getFileDetail, getThumbnail } = useSelector(
    (state) => state.file
  );

  const { ipInfoData } = useSelector((state) => state.ipInfo);
  const [videoThumbnail, setVideoThumbnail] = useState();

  const [videoName, setVideName] = useState();
  const [fileSize, setFileSize] = useState();
  const [isFivePercentWatched, setIsFivePercentWatched] = useState(false);
  const [subtitleList, setSubtitleList] = useState([]);
  const [videoSettingResponse, setVideoSettingResponse] = useState(null);

  useEffect(() => {
    dispatch(getFileUrl(id));
    dispatch(getVideoFileDetails(id)).then((response) => {
      if (response?.payload?.success === true) {
        dispatch(getVideoSignedUrl(response?.payload?.data?.name))
      }
    })
  }, [id, dispatch]);

  useEffect(() => {
    if (gettedfileData.url != "") {
      // setVideoUrl(gettedfileData.url);
      setVideName(gettedfileData.fileName);
      setFileSize(gettedfileData.fileSize);
    }
  }, [gettedfileData]);


  useEffect(() => {
    if (getFileDetail?.data?.name && id) {
      getVideoPlayUrl(getFileDetail?.data?.name, id);
    } else {
      console.warn("getFileDetail or id is missing.");
    }
  }, [getFileDetail, id]);


  const getVideoPlayUrl = async (fileName, fileKey) => {
    const url = process.env.REACT_APP_API_URL;
    if (!url) {
      throw new Error("Not able to find the URL");
    }

    try {
      const response = await axios.get(`${url}/user/getFileUrlV2?filename=${fileName}&key=${fileKey}`);
      setVideoSettingResponse(response?.data?.data);
      setVideoUrl(response?.data?.data?.url)
      setVideoThumbnail(response?.data?.data?.thumbnailUrl)
      // setSubtitle(`${response?.data?.data?.subtitles}`)

    } catch (error) {
      if (error?.response?.data?.success === false) {
        // toast.error(error?.response?.data?.message)
      }
      // console.error("Error fetching video URL:", error);
      // throw error;
    }
  };

  useEffect(() => {
    dispatch(getIpAddressService());
  }, [dispatch]);

  const handleTimeUpdate = () => {
    const video = playerRef.current;
    const currentTime = video?.currentTime;
    const duration = video.duration;

    if (duration > 0) {
      const percentageWatched = (currentTime / duration) * 100;
      if (percentageWatched >= 5 && !isFivePercentWatched) {
        setIsFivePercentWatched(true);
        const data = {
          id: id,
          ip: ipInfoData.ip,
          country: ipInfoData.country,
          code: ipInfoData.country,
          isAdblock: adBlockDetected === true ? 1 : 0
        };
        dispatch(videoViewCount(data));
      }
    }
  };

  const playerRef = useRef(null);
  const [isPlyrLoaded, setIsPlyrLoaded] = useState(false);
  const [subtitleUrl, setSubtitleUrl] = useState([]);

  // Function to make the object dynamic
  const createCaptionsArray = (subtitles) => {
    return subtitles?.map((subtitle, index) => ({
      url: subtitleUrl[index],
      language: subtitle.lang.toLowerCase().substring(0, 2), // To get short language code
      label: subtitle.language.name,
      // default: index === 0 // Set the first subtitle as default
    }));
  };

  // Generate dynamic captions array
  const captions = createCaptionsArray(subtitleList);


  useEffect(() => {
    let thumbnail = getFileDetail?.data?.thumbnail;
    if (thumbnail !== undefined && thumbnail !== '') {
      dispatch(getVideoThumbnail(thumbnail));
    }
    let subtitle = getFileDetail?.data?.subtitles;
    setSubtitleList(subtitle);
    let subtitleArray = [];
    const fetchSubtitles = async () => {
      if (subtitle !== undefined && subtitle.length > 0) {
        const promises = subtitle.map(item => {
          // return dispatch(getVideoSubtitle(item?.subtitlefile)).then((res) => {
          //   return res?.payload?.data?.url;  // Return the subtitle URL
          // });
          return item.subtitlefile;
        });
        subtitleArray = await Promise.all(promises); // Wait for all promises to resolve
        setSubtitleUrl(subtitleArray);
      }
    };

    fetchSubtitles();
    // Function to dynamically load a script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };
    let hls;
    // Load both the Plyr and HLS.js scripts
    Promise.all([
      loadScript('https://cdn.plyr.io/3.7.2/plyr.js'), // Plyr CDN
      loadScript('https://cdnjs.cloudflare.com/ajax/libs/hls.js/1.1.5/hls.min.js') // HLS.js CDN
    ])
      .then(() => {
        if (window.Plyr && window.Hls) {
          const player = new window.Plyr(playerRef.current, {
            title: 'Example Title',
            controls: [
              'play', 'progress', 'current-time', 'duration', 'mute', 'volume',
              'fullscreen', 'captions', 'settings', 'pip', 'title'
            ],
            settings: ['captions', 'quality', 'speed', 'loop'],
            captions: {
              active: true,
              update: true
            },
            // quality: {
            //   default: 576,
            //   options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
            //   forced: true,
            //   onChange: (e) => {
            //     console.log(e, 'anurag');
            //   },
            // },
            // thumbnail: {
            //   enabled: true,
            //   pic_num: 184,
            //   width: 178,
            //   height: 100,
            //   col: 7,
            //   row: 7,
            //   offsetX: 0,
            //   offsetY: 0,
            //   urls: [
            //   ]
            // },
          });
          const controls = player.elements.controls;
          if (controls) {
            // Create custom button wrapper using vanilla JS
            const customButtonWrapper = document.createElement('div');
            customButtonWrapper.className = 'plyr__control plyr__control--custom';

            // Create the button
            const customButton = document.createElement('button');
            customButton.setAttribute('type', 'button');
            customButton.setAttribute('aria-label', 'Visit SaveFiles');
            customButton.className = 'plyr__control plyr__control--icon';
            customButton.onclick = () => {
              window.open('https://savefiles.com/', '_blank'); // Open the URL in a new tab
            };

            // Use React Icon (TbSpiral) in the button
            const IconComponent = () => <TbSpiral size={24} color="#fff" />;

            // Add tooltip span element to the button
            const tooltipSpan = document.createElement('span');
            tooltipSpan.className = 'plyr__tooltip'; // Tooltip class as required by Plyr
            tooltipSpan.innerText = 'Visit SaveFiles'; // Text for the tooltip

            // Append both the custom button and tooltip to the button wrapper
            customButton.appendChild(tooltipSpan); // Add the tooltip alongside the icon
            customButtonWrapper.appendChild(customButton); // Append the button to the wrapper
            controls.appendChild(customButtonWrapper); // Append the wrapper to Plyr controls

            // Render the React icon component into the button using ReactDOM
            import('react-dom').then(ReactDOM => {
              const iconContainer = document.createElement('div'); // Create a container for the icon
              iconContainer.className = 'custom-control';
              customButton.appendChild(iconContainer); // Append it to the button
              ReactDOM.render(<IconComponent />, iconContainer); // Render the icon inside the button
            });
          }
          var plyr = document.querySelector('.video-container>.plyr');
          var overlay = document.querySelector(".channel-icon")

          // plyr.append(overlay)

          let chunkName = getFileDetail?.data?.chunkName;
          let quality = getFileDetail?.data?.quality[0]?.resolution;
          if (typeof getFileDetail?.data?.quality !== 'undefined' && getFileDetail?.data?.quality?.length > 0) {
            const videoSrc = videoUrl;
            const video = playerRef.current;

            if (video && Hls.isSupported()) {
              const hls = new Hls();
              hls.loadSource(videoSrc);
              hls.attachMedia(video);

              // hls.on(Hls.Events.MANIFEST_PARSED, () => {
              //     video.play().catch((error) => {
              //         console.error("Error playing video:", error);
              //     });
              // });

              hls.on(Hls.Events.ERROR, (event, data) => {
                console.error('HLS error:', data);
              });

              // Clean up Hls instance on unmount
              return () => {
                hls.destroy();
              };
            } else if (video && video.canPlayType('application/vnd.apple.mpegurl')) {
              // Fallback for Safari
              video.src = videoSrc;
              video.addEventListener('loadedmetadata', () => {
                video.play().catch((error) => {
                  console.error("Error playing video on Safari:", error);
                });
              });
            } else {
              console.error('HLS not supported in this browser');
            }
          }

          setIsPlyrLoaded(true);

          // Cleanup on unmount
          return () => {
            if (player) player.destroy();
          };
        }
      })
      .catch(error => console.error(error));
  }, [videoUrl, getFileDetail]); // Include videoUrl as dependency

  console.log(videoSettingResponse, 'videoSettingResponse');
  const adLevel = videoSettingResponse?.settings?.adLevelEmbed;
  useEffect(() => {
    // if (showAds === 0 || showAds === false) {
    const addScript = (src, adLevel, attributes = {}) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.type = "text/javascript";
      script.setAttribute("data-cfasync", "false");
      script.setAttribute("data-ad-level", adLevel); // Add a custom attribute for tracking
      // Add additional attributes to the script tag
      Object.entries(attributes).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });

      document.body.appendChild(script);
    };

    // Remove existing scripts (optional)
    const existingScripts = document.querySelectorAll("script[data-ad-level]");
    existingScripts.forEach((script) => script.remove());

    // Dynamically add scripts based on ad level
    switch (adLevel) {
      case "MINIMUM":
        addScript("//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1120892", "MINIMUM");
        break;
      case "MEDIUM":
        addScript("//pl24693021.profitablecpmrate.com/24/b3/5b/24b35b4bb557a50f76894b8e8bccd7f5.js", "MEDIUM");
        addScript("//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1120892", "MEDIUM");
        break;
      case "NORMAL":
        addScript("//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1120892", "NORMAL");
        addScript("//am.noouplit.com/rkPAvyGxuO83rX6RI/110475", "NORMAL");
        addScript("//pl24693021.profitablecpmrate.com/24/b3/5b/24b35b4bb557a50f76894b8e8bccd7f5.js", "NORMAL");
        break;
      case "MAXIMUM":
        addScript("//dcbbwymp1bhlf.cloudfront.net/?wbbcd=1120892", "MAXIMUM");
        addScript("//pl24693021.profitablecpmrate.com/24/b3/5b/24b35b4bb557a50f76894b8e8bccd7f5.js", "MAXIMUM");
        addScript("//am.noouplit.com/rkPAvyGxuO83rX6RI/110475", "MAXIMUM");
        addScript("https://alwingulla.com/88/tag.min.js", "MAXIMUM", {
          "data-zone": "105921",
          "data-cfasync": "false",
        });
        break;
      default:
        break;
      // }
    }
    // Optional Cleanup (if scripts need to be removed on unmount)
    return () => {
      document.querySelectorAll(`script[data-ad-level="${adLevel}"]`).forEach((script) => {
        document.body.removeChild(script);
      });
    };
  }, [adLevel]);

  return (
    <>
      <div className="full-resolution">
        <video
          ref={playerRef}
          // onPlay={handlePlay}
          onTimeUpdate={handleTimeUpdate}
          controls
          data-plyr-config='{ "title": "Example Title" }'
          aria-label='{"title": "Example Title"}'
          // poster={getThumbnail?.data?.url}
          poster={videoThumbnail}
          crossOrigin="anonymous"
        >
          <source src={videoUrl} type={'video/mp4'} />
          {captions?.map((caption, index) => (
            <track
              key={index}
              src={caption.url}
              kind="subtitles"
              srcLang={caption.language}
              label={caption.label}
            // default={captions[0].default}
            />
          ))}
        </video>
      </div>
    </>
  );
};

export default EmbeddedVideoPlayer;
