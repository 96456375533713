// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.features-section {
    hr {
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 1296px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
    .card-smiple {
        border: 1px solid #FFFFFF1A;
        box-shadow: 0px 0px 40px 0px #00000080;
        border-radius: 12px;
        padding: 20px;

        .icons-box {
            position: relative;
            width: 45px;
            height: 45px;
            margin-bottom: 20px;

            &:after {
                content: "";    
                width: 45px;
                height: 45px;
                background: var(--text_blue);
                border-radius: 8px;
                border: 0.52px solid #FFFFFF1A;
                animation: rotateanimation 3s infinite linear;
                position: absolute;
                left: 0;
                top: 0;
            }

            svg {
                display: block;
                animation: none;
                position: relative;
                z-index: 1;
            }

        }

        p {
            line-height: 24px;
        }

    }

}`, "",{"version":3,"sources":["webpack://./src/Pages/Landing/FeatureSection/FeatureStyle.css"],"names":[],"mappings":";AACA;IACI;QACI,kBAAkB;QAClB,MAAM;QACN,WAAW;QACX,iBAAiB;QACjB,cAAc;QACd,OAAO;QACP,QAAQ;IACZ;IACA;QACI,2BAA2B;QAC3B,sCAAsC;QACtC,mBAAmB;QACnB,aAAa;;QAEb;YACI,kBAAkB;YAClB,WAAW;YACX,YAAY;YACZ,mBAAmB;;YAEnB;gBACI,WAAW;gBACX,WAAW;gBACX,YAAY;gBACZ,4BAA4B;gBAC5B,kBAAkB;gBAClB,8BAA8B;gBAC9B,6CAA6C;gBAC7C,kBAAkB;gBAClB,OAAO;gBACP,MAAM;YACV;;YAEA;gBACI,cAAc;gBACd,eAAe;gBACf,kBAAkB;gBAClB,UAAU;YACd;;QAEJ;;QAEA;YACI,iBAAiB;QACrB;;IAEJ;;AAEJ","sourcesContent":["\n.features-section {\n    hr {\n        position: absolute;\n        top: 0;\n        width: 100%;\n        max-width: 1296px;\n        margin: 0 auto;\n        left: 0;\n        right: 0;\n    }\n    .card-smiple {\n        border: 1px solid #FFFFFF1A;\n        box-shadow: 0px 0px 40px 0px #00000080;\n        border-radius: 12px;\n        padding: 20px;\n\n        .icons-box {\n            position: relative;\n            width: 45px;\n            height: 45px;\n            margin-bottom: 20px;\n\n            &:after {\n                content: \"\";    \n                width: 45px;\n                height: 45px;\n                background: var(--text_blue);\n                border-radius: 8px;\n                border: 0.52px solid #FFFFFF1A;\n                animation: rotateanimation 3s infinite linear;\n                position: absolute;\n                left: 0;\n                top: 0;\n            }\n\n            svg {\n                display: block;\n                animation: none;\n                position: relative;\n                z-index: 1;\n            }\n\n        }\n\n        p {\n            line-height: 24px;\n        }\n\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
