import React, {useEffect, useState, useRef} from "react";
import Boxyellow from "../../../assets/images/box-yellow.png";
import Bluebox from "../../../assets/images/box-blue.png";
import Redbox from "../../../assets/images/red-box.png";
import EmailIcons from "../../../assets/images/email.png";
import PasswordIcons from "../../../assets/images/password.png";
import "../SignStyles.css";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {loginUser, resetLoginStatus} from "../../../features/auth/authSlice";
import {FiEye, FiEyeOff} from "react-icons/fi";
import {toast} from "react-toastify";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isAuthenticated, error, status} = useSelector(state => state.auth);
  const {register, handleSubmit} = useForm();
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    if (isAuthenticated) {
      toast.success("Logged in successfully", {});
      const selectedPlan = localStorage.getItem("plan") !== null;
      if (selectedPlan) {
        navigate("/pricing");
        return;
      }
      navigate("/dashboard");
    }

    toast.error(error, {});
    dispatch(resetLoginStatus());
  }, [isAuthenticated, navigate, error, status]);

  const onSubmit = data => {
    let requestObj = {
      email: data.email,
      password: data.password,
    };
    dispatch(loginUser(requestObj));
    //if successfull registrations
  };

  return (
    <section className="login-sec p-100 position-relative">
      <div className="banner-animation-icons">
        <span className="yellow-box">
          <img src={Boxyellow} alt="" />
        </span>
        <span className="box-blue">
          <img src={Bluebox} alt="" />
        </span>
        <span className="red-box">
          <img src={Redbox} alt="" />
        </span>
      </div>
      <div className="form-sec-details position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 text-center">
              <div className="heading-head mb-5">
                <span className="sub-heading">Welcome Back!</span>
                <h2>Login into your account</h2>
              </div>
            </div>

            <div className="form-box-main">
              <div className="form-sec">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-4 form-with-icons position-relative">
                    <label htmlFor="" className="form-label">
                      Email <span className="error-defin">*</span>
                    </label>
                    <input
                      {...register("email", {required: true})}
                      type="email"
                      className="form-control"
                      id=""
                      aria-describedby=""
                      placeholder="Enter email address"
                      required
                    />
                    <span className="icons-left">
                      <img src={EmailIcons} alt="" />
                    </span>
                  </div>
                  {/* {errors.email && <p className="error-defin">This field is required</p>} */}
                  <div className="mb-2 form-with-icons  position-relative">
                    <label htmlFor="" className="form-label">
                      Enter password <span className="error-defin">*</span>
                    </label>
                    <input
                      {...register("password", {required: true})}
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      id=""
                      placeholder="Enter password"
                      required
                    />
                    <span className="icons-left">
                      <img src={PasswordIcons} alt="" />
                    </span>
                    <span
                      className="hide-show-icons"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                  {/* {errors.password && <div className="short-discription mb-4">
                    It must be a combination of minimum 8 letters, numbers, and
                    symbols.
                  </div>} */}
                  <div className="mb-4 form-check d-flex justify-content-between flex-wrap align-items-center">
                    <div className="form-check cust-check d-flex align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault">
                        Remember password
                      </label>
                    </div>
                    {/* <label className="cust-check">Remember password
                                        <input type="checkbox"  htmlFor="" checked="checked" />
                                        <span className="checkmark"></span>
                                    </label> */}
                    <Link to={"/reset-password"} className="link-btn">
                      Forgot your password?
                    </Link>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 blue-btn">
                    Login
                  </button>
                  <div className="border-btm text-center mt-4 mb-4 position-relative">
                    <span>Or</span>
                  </div>
                  <div className="text-btm text-center">
                    Don’t have an account?{" "}
                    <Link to={"/signup"} className="link-btn">
                      {" "}
                      Create a account
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
