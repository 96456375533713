import React, {lazy, Suspense} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Landing from "../Pages/Landing";
import AuthRoute from "./AuthRoute";
import SignIn from "../Pages/Sign/SignIn/SignIn";
import SignUp from "../Pages/Sign/SignUp/SignUp";
import UnauthenticatedLayout from "../Layouts/UnauthenticatedLayout";
import ForgotPassword from "../Pages/Sign/ForgotPassword/ForgotPassword";
import AboutUs from "../Pages/AboutUs/AboutUs";
import ContactUs from "../Pages/ContactUs/ContactUs";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import CopyrightPolicy from "../Pages/CopyRight/CopyRight";
import Loading from "../Components/Loading/Loading.jsx";
import PartnerProgram from "../Pages/PartnerProgram/PartnerProgram.jsx";
import VideoPlayer from "../Pages/VideoPlayer/VideoPlayer.jsx";
import PlayerPrivateRoute from "./PlayerPrivateRoute.jsx";
import EmbeddedVideoPlayer from "../Pages/EmbeddedVideoPlayer/EmbeddedVideoPlayer.jsx";
import Pricing from "../Pages/Pricing/Pricing.jsx";
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop.jsx";
import NotFound from "../Pages/NotFound/NotFound.jsx";
import TermsAndCondition from "../Pages/TermsAndCondition/TermsAndCondition.jsx";

const Dashboard = lazy(() => import("../Pages/Dashboard/DashboardLayout.jsx"));

const UserRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <>
              <Loading></Loading>
            </>
          }>
          <ScrollToTop></ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={
                <UnauthenticatedLayout>
                  <Landing></Landing>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/signin"
              element={
                <UnauthenticatedLayout>
                  <SignIn></SignIn>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/signup"
              element={
                <UnauthenticatedLayout>
                  <SignUp></SignUp>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/reset-password"
              element={
                <UnauthenticatedLayout>
                  <ForgotPassword></ForgotPassword>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/support"
              element={
                <UnauthenticatedLayout>
                  <ContactUs></ContactUs>
                </UnauthenticatedLayout>
              }></Route>
              <Route
              path="/terms-and-conditions"
              element={
                <UnauthenticatedLayout>
                  <TermsAndCondition></TermsAndCondition>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/pricing"
              element={
                <UnauthenticatedLayout>
                  <Pricing></Pricing>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/about-us"
              element={
                <UnauthenticatedLayout>
                  <AboutUs></AboutUs>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/contact-us"
              element={
                <UnauthenticatedLayout>
                  <ContactUs></ContactUs>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/privacy-policy"
              element={
                <UnauthenticatedLayout>
                  <PrivacyPolicy></PrivacyPolicy>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/copyright-policy"
              element={
                <UnauthenticatedLayout>
                  <CopyrightPolicy></CopyrightPolicy>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/partner-program"
              element={
                <UnauthenticatedLayout>
                  <PartnerProgram></PartnerProgram>
                </UnauthenticatedLayout>
              }></Route>
            <Route
              path="/dashboard/*"
              element={
                <AuthRoute>
                  <Dashboard></Dashboard>
                </AuthRoute>
              }></Route>

            {/* nomal long videoLink */}
            <Route
              path="/v/:id/:name"
              element={
                <PlayerPrivateRoute>
                  <VideoPlayer />
                </PlayerPrivateRoute>
              }></Route>

            {/* nomal short videoLink */}
            <Route
              path="/v/:id/"
              element={
                <PlayerPrivateRoute>
                  <VideoPlayer />
                </PlayerPrivateRoute>
              }></Route>

            {/* normal embeded link */}
            <Route
              path="/e/:id/:name"
              element={<EmbeddedVideoPlayer />}></Route>

            {/* normal embeded link */}
            <Route path="/e/:id/" element={<EmbeddedVideoPlayer />}></Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default UserRoutes;
