import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getDirectory, createDirectory, deleteDirectory, updateDirectory, deleteFileService, getFileUrlService, renameFileService, videoViewCountService, getInactiveFilesService, moveFilesService, getFileDetails,
    getSignedUrl,
    getThumbnail,
    getSubtitle,
    getFileUrlServiceV2,
    getOtherFileDetail
} from '../../services/fileService';

export const getFolderData = createAsyncThunk(
    'file/getFolderData',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getDirectory(data);
            return response.data.data;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const createFolder = createAsyncThunk(
    'file/createFolder',
    async (data, { rejectWithValue }) => {
        try {
            const response = await createDirectory(data);
            return response.data;
        } catch (err) {
            if (err && err.response && err.response.status == 401) {
                return rejectWithValue('Unauthorized');
            } else if (err && err.response && err.response.status == 400) {
                return err.response.data
            }

            rejectWithValue(err.message.data);
        }
    }
)

export const deleteFolder = createAsyncThunk(
    'file/deleteFolder',
    async (data, { rejectWithValue }) => {
        try {
            const response = await deleteDirectory(data);
            response.data.id = data.dirId;
            return response.data;
        } catch (err) {
            if (err && err.response && err.response.status == 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const updateFolder = createAsyncThunk(
    'file/updateFolder',
    async (data, { rejectWithValue }) => {
        try {
            const response = await updateDirectory(data);
            response.data.id = data.dirId;
            response.data.newName = data.name;
            response.data.type = data.type ? data.type : 'RENAME'
            response.data.item = data.item ? data.item : null
            return response.data;
        } catch (err) {
            if (err && err.response && err.response.status == 401) {
                return rejectWithValue('Unauthorized');
            }
            return err.response.data
            // rejectWithValue(err.message.data);
        }
    }
)

export const renameFile = createAsyncThunk(
    'file/renameFile',
    async (data, { rejectWithValue }) => {
        try {
            const response = await renameFileService(data);
            response.data.id = data.id;
            response.data.newName = data.newFileName;
            response.data.type = data.type ? data.type : 'RENAME'
            return response.data;
        } catch (err) {
            if (err && err.response && err.response.status == 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const deleteFile = createAsyncThunk(
    'file/deleteFile',
    async (data, { rejectWithValue }) => {
        try {
            const response = await deleteFileService(data);
            response.data.id = data.id;
            return response.data;
        } catch (err) {
            if (err && err.response && err.response.status == 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const getFileUrl = createAsyncThunk(
    'file/getFileUrl',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getFileUrlService(data);
            return response.data;
        } catch (err) {
            if (err && err.response && err.response.status == 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const getFileUrlV2 = createAsyncThunk(
    'file/getFileUrlV2',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getFileUrlServiceV2(data);
            return response.data;
        } catch (err) {
            if (err && err.response && err.response.status == 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const videoViewCount = createAsyncThunk(
    'file/viewVideo',
    async (data, { rejectWithValue }) => {
        try {
            const response = await videoViewCountService(data);
            return response.data;
        } catch (err) {
            rejectWithValue(err.message.data);
        }
    }
)

export const getInactiveFiles = createAsyncThunk(
    'file/inactiveFiles',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getInactiveFilesService();
            return response.data;
        } catch (err) {
            rejectWithValue(err.message.data);
        }
    }
)

export const moveFiles = createAsyncThunk(
    'file/moveFiles',
    async (data, { rejectWithValue }) => {
        try {
            const response = await moveFilesService(data);
            return response.data;
        } catch (err) {
            rejectWithValue(err.message.data);
        }
    }
)

export const getVideoFileDetails = createAsyncThunk(
    'file/videoFiles',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getFileDetails(data);
            return response.data;
        } catch (err) {
            rejectWithValue(err.message.data);
        }
    }
)

export const getVideoSignedUrl = createAsyncThunk(
    'file/signed-url',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getSignedUrl(data);
            return response.data;
        } catch (err) {
            rejectWithValue(err.message.data);
        }
    }
)

export const getVideoThumbnail = createAsyncThunk(
    'file/video-thumbnail',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getThumbnail(data);
            return response.data;
        } catch (err) {
            rejectWithValue(err.message.data);
        }
    }
)

export const getVideoSubtitle = createAsyncThunk(
    'file/video-subtitle',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getSubtitle(data);
            return response.data;
        } catch (err) {
            rejectWithValue(err.message.data);
        }
    }
)
export const getOtherFileDetails = createAsyncThunk(
    'file/files-details',
    async (data, { rejectWithValue }) => {
        try {
            const response = await getOtherFileDetail(data);
            return response.data;
        } catch (err) {
            rejectWithValue(err.message.data);
        }
    }
)
const fileSlice = createSlice({
    name: 'file',
    initialState: {
        status: 'idle',
        data: [],
        initialData: [],
        error: null,
        createFolderStatus: 'idle',
        deleteFolderStatus: 'idle',
        filterString: '',
        updateFolderStatus: 'idle',
        moveFolderStatus: 'idle',
        currentDirectoryHasFile: true,
        selectedFiles: [],
        deleteFileStatus: 'idle',
        getFileUrlStatus: 'idle',
        gettedfileData: '',
        renameFileStatus: 'idle',
        videoViewCountStatus: 'idle',
        inactiveFiles: [],
        getInactiveFilesStatus: 'idle',
        getInactiveFilesData: [],
        createFolderErrorMessage: '',
        isMultiFileSeletected: false,
        deleteFileMessage: '',
        updateFolderMessage: '',
        moveFilesStatus: 'idle',
        getFileDetail: {},
        getSignedUrl: {},
        getOtherFileDetail: {}
    },
    reducers: {
        setFilter: (state, action) => {
            let arr = [], temp = state.initialData;
            for (let i = 0; i < temp.length; i++) {
                let s = temp[i]["originalFileName"].toLowerCase();
                if (s.includes(action.payload)) {
                    arr.push(temp[i]);
                }
            }
            state.data = arr
            state.filterString = action.payload;
        },
        setDeleteFolderStatus: (state) => {
            state.deleteFolderStatus = 'idle';
        },
        setDeleteFileStatus: (state) => {
            state.deleteFileStatus = 'idle';
        },
        setCreateFolderStatus: (state) => {
            state.createFolderStatus = 'idle';
        },
        setRenameFolderStatus: (state) => {
            state.updateFolderStatus = 'idle';
        },
        setMoveFolderStatus: (state) => {
            state.moveFolderStatus = 'idle';
        },
        setCheckBoxStatus: (state, action) => {
            let count = 0;

            state.data.forEach((item, key) => {
                if (item["isChecked"]) {
                    count++;
                }

                if (item["id"] == action.payload.id) {
                    if (!action.payload.isChecked) {
                        state.selectedFiles.push(item);
                    } else {
                        let items = state.selectedFiles;
                        if (items.length > 0) {
                            let removeId = action.payload.id;
                            const removeById = (array, id) => array.filter(item => item.id !== id);
                            state.selectedFiles = removeById(items, removeId);
                        }
                    }
                    item["isChecked"] = !action.payload.isChecked
                }
            })

            if (count >= 2) {
                state.isMultiFileSeletected = true;
            } else {
                state.isMultiFileSeletected = false;
            }
        },
        setAllCheckBoxStatus: (state, action) => {
            if (action.payload.isChecked) {
                state.data.forEach((item, key) => {
                    if (item["type"] != "DIR") {
                        item["isChecked"] = true;
                    }
                })
            } else {
                state.data.forEach((item, key) => {
                    if (item["type"] != "DIR") {
                        item["isChecked"] = false;
                    }
                })
                state.selectedFiles = []
            }
        },
        resetValues: (state, action) => {
            state.updateFolderMessage = ''
            state.updateFolderStatus = 'idle'
            state.renameFileStatus = 'idle'
            state.createFolderStatus = 'idle'
            state.moveFilesStatus = 'idle'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFolderData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getFolderData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                if (state.data && state.data.length > 0) {
                    state.data.reverse();
                    state.data.forEach((item, key) => {
                        if (item["type"] != "DIR") {
                            state.currentDirectoryHasFile = false;
                        }
                        item['isChecked'] = false;
                    })
                }
                state.selectedFiles = []
                state.initialData = state.data;
            })
            .addCase(getFolderData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(createFolder.pending, (state) => {
                state.createFolderStatus = "loading";
            })
            .addCase(createFolder.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.createFolderStatus = "succeeded";
                    state.data = [...state.data, action.payload.data]
                    state.initialData = state.data
                } else {
                    state.createFolderStatus = "failed"
                    state.createFolderErrorMessage = action.payload.message
                }
            })
            .addCase(createFolder.rejected, (state) => {
                state.createFolderStatus = "failed";
            })
            .addCase(deleteFolder.pending, (state) => {
                state.deleteFolderStatus = "loading";
            })
            .addCase(deleteFolder.fulfilled, (state, action) => {
                state.deleteFolderStatus = "succeeded";
                if (action.payload.message === "Deleted SuccessFully" && action.payload.success) {
                    let newArray = []
                    for (let i = 0; i < state.data.length; i++) {
                        if (state.data[i]["id"] && state.data[i]["id"] != action.payload.id) {
                            newArray.push(state.data[i])
                        }
                    }
                    state.data = newArray;
                }
                state.initialData = state.data
            })
            .addCase(deleteFolder.rejected, (state) => {
                state.deleteFolderStatus = "failed";
            })
            .addCase(updateFolder.pending, (state) => {
                state.updateFolderStatus = 'loading'
            })
            .addCase(updateFolder.fulfilled, (state, action) => {
                if (action.payload.success) {
                    if (action.payload.type == "RENAME") {
                        state.data.forEach((item, key) => {
                            if (item["id"] == action.payload.id) {
                                item["originalFileName"] = action.payload.newName
                                return;
                            }
                        })
                        state.updateFolderStatus = 'succeeded';
                    } else if (action.payload.type == "MOVE") {
                        state.data.push(action.payload.item)
                        state.moveFolderStatus = "succeeded"
                    } else {
                        alert("COPY action is called")
                    }
                } else {
                    state.updateFolderStatus = 'failed';
                    state.updateFolderMessage = action.payload.message
                }
            })
            .addCase(updateFolder.rejected, (state) => {
                state.updateFolderStatus = 'failed';
            })
            .addCase(renameFile.pending, (state) => {
                state.renameFileStatus = 'loading'
            })
            .addCase(renameFile.fulfilled, (state, action) => {
                if (action.payload.success) {
                    if (action.payload.type == "RENAME") {
                        state.data.forEach((item, key) => {
                            if (item["id"] == action.payload.id) {
                                item["originalFileName"] = action.payload.newName
                                return;
                            }
                        })
                        state.renameFileStatus = 'succeeded';
                    }
                }
            })
            .addCase(renameFile.rejected, (state) => {
                state.renameFileStatus = 'failed';
            })
            .addCase(deleteFile.pending, (state) => {
                state.deleteFileStatus = 'loading'
            })
            .addCase(deleteFile.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.deleteFileStatus = "succeeded";
                    state.deleteFileMessage = action.payload.message
                    let newArray = []
                    for (let i = 0; i < state.data.length; i++) {
                        if (state.data[i]["id"] && state.data[i]["id"] != action.payload.id) {
                            newArray.push(state.data[i])
                        }
                    }
                    state.data = newArray;
                    state.selectedFiles = []
                } else {
                    state.deleteFileStatus = "failed";
                }
                state.initialData = state.data
            })
            .addCase(deleteFile.rejected, (state) => {
                state.deleteFileStatus = "failed";
            })
            .addCase(getFileUrl.pending, (state) => {
                state.getFileUrlStatus = 'loading'
            })
            .addCase(getFileUrl.fulfilled, (state, action) => {
                state.getFileUrlStatus = "succeeded";
                state.gettedfileData = action.payload?.data
            })
            .addCase(getFileUrl.rejected, (state) => {
                state.getFileUrlStatus = "failed";
            })
            .addCase(getFileUrlV2.pending, (state) => {
                state.getFileUrlV2Status = 'loading'
            })
            .addCase(getFileUrlV2.fulfilled, (state, action) => {
                state.getFileUrlV2Status = "succeeded";
                state.gettedfileData = action.payload?.data
            })
            .addCase(getFileUrlV2.rejected, (state) => {
                state.getFileUrlStatus = "failed";
            })
            .addCase(videoViewCount.pending, (state) => {
                state.videoViewCountStatus = 'loading'
            })
            .addCase(videoViewCount.fulfilled, (state, action) => {
                state.videoViewCountStatus = "succeeded";
            })
            .addCase(videoViewCount.rejected, (state) => {
                state.videoViewCountStatus = "failed";
            })
            .addCase(getInactiveFiles.pending, (state) => {
                state.getInactiveFilesStatus = 'loading'
            })
            .addCase(getInactiveFiles.fulfilled, (state, action) => {
                if (action.payload.success) {
                    state.getInactiveFilesStatus = "succeeded";
                    state.getInactiveFilesData = action.payload.data.inactiveFiles
                } else {
                    state.getInactiveFilesStatus = "failed";
                }
            })
            .addCase(getInactiveFiles.rejected, (state) => {
                state.getInactiveFilesStatus = "failed";
            })
            .addCase(moveFiles.pending, (state) => {
                state.moveFilesStatus = 'loading'
            })
            .addCase(moveFiles.fulfilled, (state, action) => {
                state.moveFilesStatus = 'succeeded'
            })
            .addCase(moveFiles.rejected, (state) => {
                state.moveFilesStatus = "failed";
            })
            .addCase(getVideoFileDetails.pending, (state) => {
                state.getFileDetail = 'loading'
            })
            .addCase(getVideoFileDetails.fulfilled, (state, action) => {
                state.getFileDetail = action.payload;
            })
            .addCase(getVideoFileDetails.rejected, (state) => {
                state.getFileDetail = "failed";
            })
            //
            .addCase(getVideoSignedUrl.pending, (state) => {
                state.getSignedUrl = 'loading'
            })
            .addCase(getVideoSignedUrl.fulfilled, (state, action) => {
                state.getSignedUrl = action.payload;
            })
            .addCase(getVideoSignedUrl.rejected, (state) => {
                state.getSignedUrl = "failed";
            })
            .addCase(getVideoThumbnail.pending, (state) => {
                state.getThumbnail = 'loading'
            })
            .addCase(getVideoThumbnail.fulfilled, (state, action) => {
                state.getThumbnail = action.payload;
            })
            .addCase(getVideoThumbnail.rejected, (state) => {
                state.getThumbnail = "failed";
            })
            .addCase(getVideoSubtitle.pending, (state) => {
                state.getSubtitle = 'loading'
            })
            .addCase(getVideoSubtitle.fulfilled, (state, action) => {
                state.getSubtitle = action.payload;
            })
            .addCase(getVideoSubtitle.rejected, (state) => {
                state.getSubtitle = "failed";
            })
            .addCase(getOtherFileDetails.pending, (state) => {
                state.getOtherFileDetail = 'loading'
            })
            .addCase(getOtherFileDetails.fulfilled, (state, action) => {
                state.getOtherFileDetail = action.payload?.data
            })
            .addCase(getOtherFileDetails.rejected, (state) => {
                state.getOtherFileDetail = "failed";
            })
    }
})

export const { resetValues, setFilter, setDeleteFolderStatus, setDeleteFileStatus, setCreateFolderStatus, setRenameFolderStatus, setMoveFolderStatus, setCheckBoxStatus, setAllCheckBoxStatus } = fileSlice.actions;
export default fileSlice.reducer;