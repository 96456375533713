import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const register = async (name, email, password) => {
  return await axios.post(`${API_URL}/user/register`, { name, email, password });
};

export const login = async (email, password) => {
  return await axios.post(`${API_URL}/user/login`, { email, password });
};

export const requestOtpService = async (data) => {
  return await axios.post(`${API_URL}/user/request-otp`, { email: data.email });
};

export const verifytOtpService = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error('Not able to find the url');
  }

  const response = await axios.post(`${url}/user/verify-otp`, { email: data.email, otp: data.otp, token: data.token });

  return response
};

export const resetPasswordService = async (data) => {
  const url = process.env.REACT_APP_API_URL;
  if (!url) {
    throw new Error('Not able to find the url');
  }

  const response = await axios.post(`${url}/user/reset-password`, { email: data.email, newPassword: data.password }, {
    headers: {
      'Authorization': `Bearer ${data.token}`
    }
  });

  return response
};