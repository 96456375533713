import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';

const PlayerPrivateRoute = ({children}) => {
    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    // const navigate = useNavigate();

    // useEffect(() => {
    //     if(!isAuthenticated){
    //         navigate('/signin')
    //     }
    // })

  return (
    <>
        <Header videoPlayer={true}></Header>
        {children}
        <Footer></Footer>
    </>
  )
}

export default PlayerPrivateRoute