import React, {useState} from "react";
import {Link} from "react-router-dom";
import {BiHomeAlt} from "react-icons/bi";
import {FiUpload} from "react-icons/fi";
import {FiUploadCloud} from "react-icons/fi";
import {RiFileAddLine} from "react-icons/ri";
import {GoLock} from "react-icons/go";
import {RiMoneyDollarCircleFill} from "react-icons/ri";

const DashboardSideMenu = () => {
  const [activeTab, setActiveTab] = useState(() => {
    if(sessionStorage.getItem('tabIndex')){
      return sessionStorage.getItem('tabIndex')
    }else{
      sessionStorage.setItem('tabIndex', 1)
      return 1
    }
  });

  return (
    <div className="sidebar-menu">
      <div className="inner-sidebar-menu">
        <ul className="main-menu">
          <li>
            <Link
              rel="stylesheet"
              to="home"
              onClick={() => {
                setActiveTab(1)
                sessionStorage.setItem('tabIndex', 1)
              }}
              className={`d-flex align-items-center ${
                activeTab == 1 ? "ActiveMenu" : ""
              }`}>
              <span className="icons">
                <BiHomeAlt />
              </span>{" "}
              <span className="text-menu">Dashboard</span>
            </Link>
          </li>
        </ul>
        <ul className="main-menu">
          <h6>Upload</h6>
          <li>
            <Link
              rel="stylesheet"
              to="upload"
              onClick={() => {
                setActiveTab(2)
                sessionStorage.setItem('tabIndex', 2)
              }}
              className={`d-flex align-items-center ${
                activeTab == 2 ? "ActiveMenu" : ""
              }`}>
              <span className="icons">
                <FiUpload />
              </span>{" "}
              <span className="text-menu">Upload</span>
            </Link>
          </li>
          <li>
            <Link
              rel="stylesheet"
              to="remote-upload"
              onClick={() => {
                setActiveTab(3)
                sessionStorage.setItem('tabIndex', 3)
              }}
              className={`d-flex align-items-center ${
                activeTab == 3 ? "ActiveMenu" : ""
              }`}>
              <span className="icons">
                <FiUploadCloud />
              </span>{" "}
              <span className="text-menu">Remote Upload</span>
            </Link>
          </li>
        </ul>
        <ul className="main-menu">
          <h6>Manage</h6>
          <li>
            <Link
              rel="stylesheet"
              to="file-manager"
              onClick={() => {
                setActiveTab(4)
                sessionStorage.setItem('tabIndex', 4)
              }}
              className={`d-flex align-items-center ${
                activeTab == 4 ? "ActiveMenu" : ""
              }`}>
              <span className="icons">
                <RiFileAddLine />
              </span>{" "}
              <span className="text-menu">Filemanager</span>
            </Link>
          </li>
          <li>
            <Link
              rel="stylesheet"
              to="partner-program"
              onClick={() => {
                setActiveTab(5)
                sessionStorage.setItem('tabIndex', 5)
              }}
              className={`d-flex align-items-center ${
                activeTab == 5 ? "ActiveMenu" : ""
              }`}>
              <span className="icons">
                <RiFileAddLine />
              </span>{" "}
              <span className="text-menu">Partner Program</span>
            </Link>
          </li>
        </ul>
        <ul className="main-menu">
          <h6>Settings</h6>
          <li>
            <Link
              rel="stylesheet"
              to="account-settings"
              onClick={() => {
                setActiveTab(7)
                sessionStorage.setItem('tabIndex', 7)
              }}
              className={`d-flex align-items-center ${
                activeTab == 7 ? "ActiveMenu" : ""
              }`}>
              <span className="icons">
                <GoLock />
              </span>{" "}
              <span className="text-menu">Account Settings</span>
            </Link>
          </li>
        </ul>
        <ul className="main-menu">
          <h6>Pricing</h6>
          <li>
            <Link
              rel="stylesheet"
              to="pricing"
              onClick={() => {
                setActiveTab(10)
                sessionStorage.setItem('tabIndex', 10)
              }}
              className={`d-flex align-items-center ${
                activeTab == 10 ? "ActiveMenu" : ""
              }`}>
              <span className="icons">
                <RiMoneyDollarCircleFill />
              </span>{" "}
              <span className="text-menu">Pricing</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardSideMenu;
