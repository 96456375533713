import React from "react";
import "./GetContactStyles.css";
import {Link} from "react-router-dom";

const GetContact = () => {
  return (
    <>
      <section className="home-contact-sec p-100 position-relative">
        <div className="container">
          <div className="row justify-content-center position-relative">
            <div className="col-lg-10 acol-md-10 text-center">
              <h3 className="mb-0">
                If you have any questions or issues about our service, please
                feel free to contact us anytime. We value your feedback and
                strive to ensure our users' satisfaction!
              </h3>
              <Link to={"/contact-us"} className="btn btn-primary mt-4">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetContact;
