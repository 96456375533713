import React, { useEffect, useState } from "react";
import "./ShareFolder.css";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";
import { useDispatch, useSelector } from "react-redux";
import { getSharedFolderData } from "../../features/shareFolder/shareFolderSlice";
import { Helmet } from 'react-helmet';
import Loading from "../Loading/Loading";


const ShareFolder = () => {

    const [loading, setLoading] = useState(true)

    // MataData variable
    const { shareFolder } = useSelector(state => state.folderShare);
    const title = 'SaveFiles | Upload & Share Files - 200GB Free';
    const description = '';
    const twitterTitle = `${shareFolder.folderSize} folder on SaveFiles`;
    const twitterDescription = '';
    const keywords = ['cloud storage', 'file storage', 'savefiles'];
    const ogImage = "https://staging.savefiles.com/images/folder-logo.png";

    const path = window.location.pathname;
    // Split the path by '/' and get the last part
    const lastKey = path.substring(path.lastIndexOf('/') + 1);

    const dispatch = useDispatch();

    useEffect(() => {
        const elements = document.getElementsByClassName('isAuthenticated');
        if (elements.length > 0) {
            elements[0].style.marginLeft = 'auto';
            const ulElement = elements[0].querySelector('ul'); // Find the <ul> inside the first matched element
            if (ulElement) {
                const liElements = ulElement.querySelectorAll('li'); // Get all <li> elements
                liElements.forEach((li, index) => {
                    if (index !== 0) {
                        li.style.display = 'none'; // Hide all <li> except the first one
                    }
                });
            }
        }
        setTimeout(() => {
            setLoading(false);
        }, 3000)
        dispatch(getSharedFolderData(lastKey));
    }, [dispatch, lastKey])

    useEffect(() => {
        setTimeout(() => {

            const lightbox = GLightbox({
                selector: ".glightbox",
                touchNavigation: true,
                loop: true,
                autoplayVideos: false,
                autoplayAudios: true,
            });

            // Add an event listener for slide change
            lightbox.on('slide_changed', (prev, current) => {
                // Check if there is a previous slide
                if (prev) {
                    // Get all media elements (video and audio) in the previous slide
                    if (Boolean(prev.prev.slideNode)) {
                        const mediaElements = prev.prev.slideNode.querySelectorAll('video, audio');
                        mediaElements.forEach((media) => {
                            if (!media.paused) {
                                media.pause(); // Pause if it's playing
                                media.currentTime = 0; // Reset to the start for consistency
                            }
                        });
                    }
                }
            });
            return () => {
                lightbox.destroy();
            };
        }, 3500);

        // Clean up the instance when the component unmounts
    }, [shareFolder]);

    const handleDownload = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = true;
        link.click();
    };

    if (loading) {
        return (<Loading />);
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                {ogImage && <meta property="og:image" content={ogImage} />}

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content={twitterTitle} />
                <meta property="twitter:description" content={twitterDescription} />
                {ogImage && <meta property="twitter:image" content={ogImage} />}
                <meta name="file:root/projects/project1/README.md" content="size:10" />
            </Helmet>
            <div className="file-upload-section webfile-upload">
                <div className="top-search p-100 pb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scroll-file pb-4">
                                    <h2 style={{ textAlign: 'center' }}>{shareFolder?.directory?.name}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-list pt-0 p-100 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scroll-file">
                                    {shareFolder?.files?.length > 0 ? (
                                        <ul className="box-container d-flex flex-wrap">
                                            {shareFolder?.files?.map((file, index) => (
                                                <li className="box" key={index}>
                                                    <div className="inner">
                                                        {file.mimeType == 'video' && (
                                                            <a href={file?.signedUrl} className="glightbox">
                                                                <span className="icons">
                                                                    <img src="/sharedImages/video-icons.png" alt="" />
                                                                </span>
                                                                <img src={file?.thumbnail ? file?.thumbnail : "/sharedImages/video-01.png"} alt="image" />
                                                                <span className="btn-slider">
                                                                    {file?.originalFileName}
                                                                </span>

                                                                <button
                                                                    className="download-btn"
                                                                    onClick={() => handleDownload(file?.signedUrl)}
                                                                >
                                                                    <img src='/sharedImages/dropdown-img.png' alt="Download" />
                                                                </button>
                                                            </a>
                                                        )}
                                                        {file.mimeType == 'audio' && (
                                                            <>
                                                                <a href="#audio-content1" className="glightbox ">
                                                                    <span className="icons">
                                                                        <img src="/sharedImages/audio-icons.png" alt="" />
                                                                    </span>
                                                                    <img src='/sharedImages/upload-file-05.png' alt="audio file" />
                                                                    <span className="btn-slider">{file?.originalFileName}</span>
                                                                </a>
                                                                <div
                                                                    id="audio-content1"
                                                                    className="lightbox-hidden audio-gallery"
                                                                    style={{ display: "none" }}
                                                                >
                                                                    <audio controls >
                                                                        <source src={file?.signedUrl} type="audio/mp3" />
                                                                        Your browser does not support the audio tag.
                                                                    </audio>
                                                                </div>
                                                            </>
                                                        )}

                                                        {file.mimeType == 'other' && (
                                                            <a href={file?.signedUrl} className="glightbox pdf">
                                                                <span className="icons">
                                                                    <img src="/sharedImages/pdf-icons.png" alt="" />
                                                                </span>
                                                                <img src="/sharedImages/pdf.png" alt="" />
                                                                <span className="btn-slider">
                                                                    {file?.originalFileName}
                                                                </span>
                                                            </a>
                                                        )}
                                                        {file.mimeType == 'image' && (
                                                            <a href={file?.signedUrl} className="glightbox">
                                                                <span className="icons">
                                                                    <img src="/sharedImages/image-icons.png" alt="" />
                                                                </span>
                                                                <img src={file?.signedUrl} alt="" />
                                                                <span className="btn-slider">{file?.originalFileName}</span>
                                                                <button
                                                                    className="download-btn"
                                                                    onClick={() => handleDownload(file?.signedUrl)}
                                                                >
                                                                    <img src="/sharedImages/dropdown-img.png" alt="Download" />
                                                                </button>
                                                            </a>
                                                        )}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className="no-items-found">
                                            <h3>No items found in this folder.</h3>
                                            <a className="btn-primary width-custom" href="/">Go back to home</a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShareFolder